/*
Carousel 
*/
.carousel-control .glyphicon{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  margin-left: -10px;
}
