@import "./variables";
@import "bootstrap";
@import "./bootstrap/app";
@import 'bootstrap-vue/dist/bootstrap-vue.css';
body {
  font-family: "Open Sans", sans-serif;
  overflow-y: scroll;
}
/*
  | -----------------------------------------------
  | 3rd party CSS/SCSS
  |
 */
@import "vue-multiselect/dist/vue-multiselect.min.css";
@import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';
@import 'timeline-vuejs/dist/timeline-vuejs.css';
@import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
/*
  | -----------------------------------------------
  | Customizations
  |
 */
@import "./global-notification";
@import "./auth-layout";
@import "./patch_cn_custom";
@import "./tabs-soft-delete";
@import "./data-table-basic";
@import "./pagination";
@import "./multi_select_dropdown";
@import "./empty-card";
@import "./section-card";
@import "./inner-layout";
@import "./help-desk-widget";
@import "./section-content-with-action";
@import "./page-create-card";
@import "./avatar";
@import "./cropping-area";
@import "./signature-area";
@import "./permissions";
@import "./transitions";
@import "./error-layouts";
@import "./utils";

// Forms & Inputs
@import "./form";
@import "./form_input-availability";
@import "./form_input-avatar";
@import "./form_input-tag";
@import "./form_input-signature";
@import "./form-input-editor";
