.app-helpdesk-widget .app-helpdesk-widget-quick-action {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px;
  z-index: 99999991!important;
}
.app-helpdesk-widget .app-helpdesk-widget-quick-action .btn-helpdesk {
  background-color: #211C6A;
  color: #fff;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  font-weight: 600;
  letter-spacing: 1px;
}
.app-helpdesk-widget .app-helpdesk-editor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: rgba(0,0,0,0.3);
  z-index: 99999999!important;
}
.app-helpdesk-widget .app-helpdesk-editor .app-helpdesk-editor-image-area {
  width: 100%;
  height: 100%;
}
.app-helpdesk-widget .app-helpdesk-editor .app-helpdesk-editor-image-area .editor-area {
  width: 100%;
  /*background: #fff;*/
  /*padding: 15px;*/
  /*border-radius: 15px;*/
}
.app-helpdesk-widget .app-helpdesk-editor .app-helpdesk-editor-image-area img {
  width: 100%;
  height: auto;
}
.app-helpdesk-widget .app-helpdesk-widget-form-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: rgba(0,0,0,0.3);
  z-index: 99999999!important;
}
.app-helpdesk-notification-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: rgba(0,0,0,0.3);
  z-index: 999999999 !important;
}
.app-helpdesk-form textarea.feedback-area-input {
  min-height: 70px;
  max-height: 150px;
}
.app-helpdesk-form .img-preview-area {
  position: relative;
}
.app-helpdesk-form .img-preview-area .preview-image-wrapper {
  width: 100%;
  max-height: 200px;
  overflow: auto;
}
.app-helpdesk-form .img-preview-area .preview-image-wrapper > img {
  width: 100%;
  height: auto;
}
.app-helpdesk-form .img-preview-area .clear-image {
  position: absolute;
  top: -15px;
  right: 20px;
  font-size: 18px;
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 36px;
  border: 1px solid red;
  color: #fff;
  background: red;
}
.app-helpdesk-form .img-preview-area .highlight-or-hide {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 28px;
  background: #fff;
  border: 1px solid red;
  border-radius: 3px;
  z-index: 3;
  margin: auto;
}
.app-helpdesk-form .helpdesk-manual-upload-image-wrapper label {
  position: relative;
  width: 100%;
  display: block;
}
.app-helpdesk-form .helpdesk-manual-upload-image-wrapper label span.btn {
  width: 100%;
  display: block;
}
.app-helpdesk-form .helpdesk-manual-upload-image-wrapper label input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}