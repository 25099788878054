.data-table-basic {
  border: 1px solid #e9e9e9;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  table {
    border-collapse: collapse;
    margin-bottom: 0 !important;
    tr {
      th, td {
        border-left: 1px solid #ebebeb;
        &.selection-cell { width: 14px; }
        &.action-cell { width: 60px; }
      }
    }
    thead {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 99;
      box-shadow: 0 2px 5px #e1e1e1;
      tr {
        th, td {
          border-bottom: 1px solid #ebebeb;
          &:not(.head-cell-sortable) {
            cursor: default;
          }
          &.head-cell-sortable {
            cursor: pointer;
          }
          .head-cell-value {
            &.sortable {
              position: relative;
              &:not(.sortable) { cursor: default; }
              &.sortable { cursor: pointer; }
              .head-cell-with-sorting  > .head-cell-sortable {
                &:after {
                  position: absolute;
                  content: "";
                  width: 20px;
                  height: 20px;
                  right: 5px;
                  top: 0;
                  bottom: 0;
                  margin: auto 0;
                }
                &[aria-sort="none"]:after {
                  background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkQzQjBFNjNBQkZEQzExRUM4ODlGRTE4ODFFOTYyQjJFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkQzQjBFNjNCQkZEQzExRUM4ODlGRTE4ODFFOTYyQjJFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RDNCMEU2MzhCRkRDMTFFQzg4OUZFMTg4MUU5NjJCMkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RDNCMEU2MzlCRkRDMTFFQzg4OUZFMTg4MUU5NjJCMkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz63bzJtAAABPElEQVR42szUu0vDUBTH8UZqfWCRQiiC4Cg4CgodBF0KOrh1Vdx8gJvgP6C70GprXRTBDA4ZhOLi5OLiIA7iJiiKgrSCb8T0e+FQQrgXEgjogU8LF3J6b87v1vI8LxFXtSVirKT6cF3Xv7aKSSzhyvBcCnlc4M60sxmsYQJV2IZmA9jAtOmYajdl3OMRw9hDr6ZZOzrRrWuWg4NjFGXr6pgjqKAj0OwX3/jRNdvGNeZRl92cYAEFLIceAFXCKZ6Rlh/pUbPBLB6iNNvxrQWD5/xJzuIPbcgaRxaHvjVLvgcxFqXZKNbxhXOJxysy2EVXlGYlyZ26GSt4kQipHA5hKmk4ekrzPj8ldwfYwpvkz5YsnukG8I4PSXiwGpjDJfrQj0W5OdoBHOEGt4bjPslN2UQN+61p/Ns/x6YAAwAiYED4kCMOrAAAAABJRU5ErkJggg==');
                }
                &[aria-sort="ascending"]:after {
                  background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjIzQzFBMjZDQkZERDExRUM5NTVCQTZERkU5Qjk4QkYzIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjIzQzFBMjZEQkZERDExRUM5NTVCQTZERkU5Qjk4QkYzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjNDMUEyNkFCRkREMTFFQzk1NUJBNkRGRTlCOThCRjMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjNDMUEyNkJCRkREMTFFQzk1NUJBNkRGRTlCOThCRjMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6s0SItAAABQUlEQVR42szUvUtCURjHca+YvVCEIBIIjUGjUNAQ1CLU0OZatPUCbUH/gO1B9r4UgQ5SDkG0NLW0NIhDtAmJUSQWlFlEt++Bp7hczgEvXKgHPgqHy+Nz7vkdLdu2A35VMOBjhdSHdXThXFvBBBZx/bN4HHx0PhNGEkVUTJNNI41x7CJqGKIfa5gybVNNs4Uq7pHAAXo1zdrQgS5dsxHkcIZ1GV1tcwjbaHc1+8IHPnXNdnCDOdRlmnPMI4Wllg+AykCdQg098iPdKGAGd16a7TnW3MHL/UnO/A9tizWGGPKONUu+BzDqpdkwVvGOK4nHCyLYR6eXZhnJnboZy3iWCKkcDmIyZNh6WPM+m5K7LDbxKvmLShYvdQfQwJsk3F1PmEUJfYhjQW6O9gBOUMatYbsPclM2cIrD39P4t3+O3wIMAP6sQfg/7BqDAAAAAElFTkSuQmCC');
                }
                &[aria-sort="descending"]:after {
                  background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjQ2MUM3MUUyQkZERDExRUNBMzFBQkIzQkM5NDg5REQ4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjQ2MUM3MUUzQkZERDExRUNBMzFBQkIzQkM5NDg5REQ4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDYxQzcxRTBCRkREMTFFQ0EzMUFCQjNCQzk0ODlERDgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDYxQzcxRTFCRkREMTFFQ0EzMUFCQjNCQzk0ODlERDgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6LoB6CAAABQUlEQVR42szUzysEYRzH8V3NDruR1CSl9qgctygHxUVxcJEruflRbmr/gXVX1u8LKXtwmIOSi5OLi4Mc5KaIKKHYRZrZ91NfmqbnqZma4luv3Xqavvt95vk8m/Z9P5VUNaQSLEt9uK4bXCtiGHO4/Fkc85zgMzaGcI5b02QTKGEQm3AMQ+SxhFHTNtU0a7jDAwrYQaumWQZNyOma9aGCIyzL6GqbPVhHY6iZhy9865pt4ArTeJZpjjGDccxHPgCqjBM8oUV+pFmdDSZxH6fZVmAtHLzKn+Qs+dBGrAG0Yz+wlpbvLvTHadaLRXziTOLxhjZsIxunWVlyp27GAl4lQiqH3RixDFu3Ne/zQ3K3h1W8S/4cyeKp7gCqqEnCw/WCKVygA52YlZujPYADXOPGsN1HuSkrOMTu72n82z/HugADAATzQlxcrMxTAAAAAElFTkSuQmCC');
                }
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        th, td {
          border-top: 1px solid #ebebeb;
          background-color: #fff;
          transition: background-color 0.3s ease-in-out;
          .anchor-link {
            cursor: pointer;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
              opacity: 0.9;
            }
          }
          .primary-value {
            color: #009cb3;
            font-weight: 600;
          }
        }
        &:last-of-type {
          td, th {
            border-bottom: 1px solid #ebebeb;
          }
        }
        &:hover {
          th:not(.empty-cell),
          td:not(.empty-cell) {
            background-color: #f7f7f7;
          }
        }
      }
    }

  }
}
