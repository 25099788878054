/*
Custom css
*/
.cursor-pointer{
  cursor: pointer;
}

.width200 .th-inner
{
  min-width: 200px;
}
.width30 .th-inner
{
  min-width: 30px;
}
.width156 .th-inner
{
  min-width: 156px;
}
.width115 .th-inner
{
  min-width: 115px;
}
.width150 .th-inner
{
  min-width: 150px;
}
.width230 .th-inner
{
  min-width: 230px;
}
.width250 .th-inner
{
  min-width: 250px;
}
.width240 .th-inner
{
  min-width: 240px;
}


.primary-text {
  color: #00acc2;
}


.bootstrap-table .fixed-table-body
{
  overflow-x: visible;
  overflow-y: visible;
}

.table-content-wrapper
{
  overflow-y: scroll;
}
.bootstrap-table .fixed-table-toolbar
{
  display: none;
}

.listView-table-container
{

}

.listView-filter-table tbody tr td
{
  vertical-align: top;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 10px;
}
.custom-datepicker
{
  width: 100% !important;
}
.custom-datepicker input{
  height: 38px;
  border: 1px solid #e8e8e8;
}
.custom-input
{
  width: 100% !important;
  height: 60px;
  border: 1px solid #e8e8e8;
}
.custom-checkbox-wrapper
{
  display: inline-block;
  margin-right: 10px;
  line-height: 35px;
}

.listView-filter-table tbody tr td .multiselect__tags
{
  min-height: 60px;
  padding: 17px 40px 0 8px;
}
.listView-filter-table tbody tr td .multiselect__select
{
  height: 57px;
}
.filter-label
{
  padding-top: 0px;
  padding-bottom: 2px;
  text-align: left;
}


/* CN-Custom Style for New Design */
.content-header .navbar{
  background-color: #00acc2;
  padding: 0.88rem 1rem;
}
#pageTitle {
  margin-top: -5px;
}
.navbar-text{
  color: #ffffff;
  padding-top: 0.6rem;
}

.sidenav.dark.dk{
  background-color: #232a34;
}
.sidenav.dark .lt{
  background-color: #00acc2;
  display: inline;
}
.sidenav .navbar-brand {
  font-size: 16px;
  line-height: 5px;
  padding: 0;
  color: #ffffff;
}
.sidenav .navbar-brand svg {
  filter: brightness(0) invert(1);
}
.sidenav .navbar-brand img{
  padding-top: 4px;
  border-radius: 0px;
}

.sidenav .nav.bg{
  padding-top: 20px;
  background-color: #232a34;
}
.sidenav .nav-text{
  white-space: normal;
}
.sidenav a:hover > .nav-icon{
  color: #ffffff;
}
.sidenav a:hover > .nav-text{
  color: #13e5ff;
}
.sidenav .nav li > a:hover, .sidenav .nav li > a:focus{
  background-color: #0C1725;
}
.nav-border .nav > li.active:after{
  content: none;
}
.nav-border .nav > li.active{
  background-color: #0C1725;
  padding: 10px 0px;
}
.sidenav .nav li > a.router-link-active {
  padding: 0 0.6rem;
  margin: 0 0.5rem;
  background-color: #00acc2;
  border-radius: 4px;
  color: #ffffff;
  opacity: 1;
}
.sidenav a.router-link-active > .nav-icon,
.sidenav a.router-link-active > .nav-text{
  color: #ffffff;
  opacity: 1;
}
.sidenav .nav li.active > a{
  padding: 0 0.6rem;
  margin: 0 0.5rem;
  background-color: #00acc2;
  border-radius: 4px;
  color: #ffffff;
  opacity: 1;
}
.sidenav .nav li.active > a > .nav-text{
  color: #ffffff;
}
.sidenav .nav li.active .nav-sub a{
  margin: 0;
  background-color: transparent;
}
.sidenav .nav li.active .nav-sub a{
  padding-left: 3.4rem;
}
.nav-sub a{
  position: relative;
}
.nav-sub .nav-text:before{
  position: absolute;
  content:"Օ";
  left: 30px;
  top: 7px;
}
.sidenav .nav li.active .nav-sub a:hover{
  background-color: #172130;
}
.sidenav .nav li.active .nav-sub a.router-link-active{
  background-color: #172130 !important;
}

.page-header {
  background-color: #fff;
  /* padding: 20px 10px; */
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}
.page-header h1{
  margin-bottom: 0px;
  margin-top: 4px;
  font-size: 28px;
}

.padding {
  padding: 2rem 1rem;
}
.padding h3{
  font-size: 1.2rem;
}
.padding .box{
  margin: 20px;
  border-radius: 8px;
  overflow: hidden;
}
.padding .fixed-table-container{
  border-radius: 6px;
}

.btn-green{
  color: #ffffff;
  background-color: #00acc2;
  font-weight: 600;
  padding: 0.46rem 1rem;
  margin: 0.2rem 0.4rem;
  border: none;
  border-radius: 4px !important;
}
.btn.btn-green:hover{
  color: #ffffff;
  background-color: #009db3;
}
.btn-green:focus{
  background-color: #00acc2 !important;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 172, 194, 0.5) !important;
  box-shadow: 0 0 0 3px rgba(0, 172, 194, 0.5) !important;
}
.btn-back{
  background-color: #fcfcfc;
  border: 1px solid #dddddd !important;
  color: #303e44;
  font-weight: 600;
  padding: 0.46rem 1rem;
  margin: 0.2rem 0.6rem 0.2rem 0.3rem;
}
.btn-back:hover,
.btn.primary.btn-back:hover{
  background-color: #e7e7e7 !important;
  color: #303e44 !important;
  border: 1px solid #dddddd !important;
}
.btn-back:focus{
  background-color: #e7e7e7 !important;
  -webkit-box-shadow: 0 0 0 3px rgba(231, 231, 231, 0.5) !important;
  box-shadow: 0 0 0 3px rgba(231, 231, 231, 0.5) !important;
}
.btn-clear-filter {
  color: #00acc2 !important;
  background-color: #fff !important;
  border-radius: 4px !important;
  border-color: #00acc2 !important;
  margin: 0.2rem 0.4rem !important;
}
.btn-clear-filter:hover {
  color: #fff !important;
  background-color: #00acc2 !important;
  border-color: #00acc2 !important;
  box-shadow: none !important;
}
.btn-search{
  color: #ffffff !important;
  background-color: #00acc2 !important;
  font-weight: 600;
  padding: 0.46rem 1rem !important;
  margin: 0.2rem 0.4rem !important;
  border-radius: 4px !important;
  border: none;
}
.btn-search:hover{
  color: #ffffff !important;
  background-color: #009db3 !important;
}
.btn-green.btn-success:not(:disabled):not(.disabled):active{
  background-color: #009db3;
  border-color: #009db3;
}


.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), .table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left){
  background-size: auto;
}
.table.b-table > thead > tr > [aria-sort="none"], .table.b-table > tfoot > tr > [aria-sort="none"]{
  background-image: none;
}
.table.b-table > thead > tr > [aria-sort="ascending"], .table.b-table > tfoot > tr > [aria-sort="ascending"]{
  background-image:  none;
}
.table.b-table > thead > tr > [aria-sort="descending"], .table.b-table > tfoot > tr > [aria-sort="descending"]{
  background-image:  none;
}

.table.b-table > thead > tr > [aria-sort="none"] > span.sort,
.table.b-table > tfoot > tr > [aria-sort="none"] > span.sort,
.table.b-table > thead > tr > [aria-sort="ascending"] > span.sort,
.table.b-table > thead > tr > [aria-sort="descending"] > span.sort{
  position: relative;
}
.table.b-table > thead > tr > [aria-sort="none"] > span.sort:before,
.table.b-table > tfoot > tr > [aria-sort="none"] > span.sort:before{
  position: absolute;
  content: '';
  background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkQzQjBFNjNBQkZEQzExRUM4ODlGRTE4ODFFOTYyQjJFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkQzQjBFNjNCQkZEQzExRUM4ODlGRTE4ODFFOTYyQjJFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RDNCMEU2MzhCRkRDMTFFQzg4OUZFMTg4MUU5NjJCMkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RDNCMEU2MzlCRkRDMTFFQzg4OUZFMTg4MUU5NjJCMkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz63bzJtAAABPElEQVR42szUu0vDUBTH8UZqfWCRQiiC4Cg4CgodBF0KOrh1Vdx8gJvgP6C70GprXRTBDA4ZhOLi5OLiIA7iJiiKgrSCb8T0e+FQQrgXEgjogU8LF3J6b87v1vI8LxFXtSVirKT6cF3Xv7aKSSzhyvBcCnlc4M60sxmsYQJV2IZmA9jAtOmYajdl3OMRw9hDr6ZZOzrRrWuWg4NjFGXr6pgjqKAj0OwX3/jRNdvGNeZRl92cYAEFLIceAFXCKZ6Rlh/pUbPBLB6iNNvxrQWD5/xJzuIPbcgaRxaHvjVLvgcxFqXZKNbxhXOJxysy2EVXlGYlyZ26GSt4kQipHA5hKmk4ekrzPj8ldwfYwpvkz5YsnukG8I4PSXiwGpjDJfrQj0W5OdoBHOEGt4bjPslN2UQN+61p/Ns/x6YAAwAiYED4kCMOrAAAAABJRU5ErkJggg==');
  right: 0;
  width: 20px;
  height: 20px;
  right: -25px;
}
.table.b-table > thead > tr > [aria-sort="ascending"] > span:before, .table.b-table > tfoot > tr > [aria-sort="ascending"] > span:before{
  position: absolute;
  content: '';
  background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjIzQzFBMjZDQkZERDExRUM5NTVCQTZERkU5Qjk4QkYzIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjIzQzFBMjZEQkZERDExRUM5NTVCQTZERkU5Qjk4QkYzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjNDMUEyNkFCRkREMTFFQzk1NUJBNkRGRTlCOThCRjMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjNDMUEyNkJCRkREMTFFQzk1NUJBNkRGRTlCOThCRjMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6s0SItAAABQUlEQVR42szUvUtCURjHca+YvVCEIBIIjUGjUNAQ1CLU0OZatPUCbUH/gO1B9r4UgQ5SDkG0NLW0NIhDtAmJUSQWlFlEt++Bp7hczgEvXKgHPgqHy+Nz7vkdLdu2A35VMOBjhdSHdXThXFvBBBZx/bN4HHx0PhNGEkVUTJNNI41x7CJqGKIfa5gybVNNs4Uq7pHAAXo1zdrQgS5dsxHkcIZ1GV1tcwjbaHc1+8IHPnXNdnCDOdRlmnPMI4Wllg+AykCdQg098iPdKGAGd16a7TnW3MHL/UnO/A9tizWGGPKONUu+BzDqpdkwVvGOK4nHCyLYR6eXZhnJnboZy3iWCKkcDmIyZNh6WPM+m5K7LDbxKvmLShYvdQfQwJsk3F1PmEUJfYhjQW6O9gBOUMatYbsPclM2cIrD39P4t3+O3wIMAP6sQfg/7BqDAAAAAElFTkSuQmCC');
  right: 0;
  width: 20px;
  height: 20px;
  right: -25px;
}
.table.b-table > thead > tr > [aria-sort="descending"] > span:before, .table.b-table > tfoot > tr > [aria-sort="descending"] > span:before{
  position: absolute;
  content: '';
  background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjQ2MUM3MUUyQkZERDExRUNBMzFBQkIzQkM5NDg5REQ4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjQ2MUM3MUUzQkZERDExRUNBMzFBQkIzQkM5NDg5REQ4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDYxQzcxRTBCRkREMTFFQ0EzMUFCQjNCQzk0ODlERDgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDYxQzcxRTFCRkREMTFFQ0EzMUFCQjNCQzk0ODlERDgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6LoB6CAAABQUlEQVR42szUzysEYRzH8V3NDruR1CSl9qgctygHxUVxcJEruflRbmr/gXVX1u8LKXtwmIOSi5OLi4Mc5KaIKKHYRZrZ91NfmqbnqZma4luv3Xqavvt95vk8m/Z9P5VUNaQSLEt9uK4bXCtiGHO4/Fkc85zgMzaGcI5b02QTKGEQm3AMQ+SxhFHTNtU0a7jDAwrYQaumWQZNyOma9aGCIyzL6GqbPVhHY6iZhy9865pt4ArTeJZpjjGDccxHPgCqjBM8oUV+pFmdDSZxH6fZVmAtHLzKn+Qs+dBGrAG0Yz+wlpbvLvTHadaLRXziTOLxhjZsIxunWVlyp27GAl4lQiqH3RixDFu3Ne/zQ3K3h1W8S/4cyeKp7gCqqEnCw/WCKVygA52YlZujPYADXOPGsN1HuSkrOMTu72n82z/HugADAATzQlxcrMxTAAAAAElFTkSuQmCC');
  right: 0;
  width: 20px;
  height: 20px;
  right: -25px;
}

.breadcrumb{
  background-color: transparent;
  float: right;
  margin: 0;
}
.breadcrumb a{
  color: #00acc2;
  font-weight: 700;
}
.breadcrumb a:hover{
  color: #009db3;
}

#welcomeMsg{
  font-size: 14px;
  margin-right: 10px;
}

.pagination .active .page-link, .pagination .active a {
  color: white !important;
  background-color: #00acc2 !important;
  border-color: #00acc2 !important;
}

.search-field input {
  width: 100%;
}

.padding .box{
  overflow: visible;
  padding-top: 1px;
}

#aside.toggle-expend-mobile, #aside.toggle-expend-mobile .scroll {
  width: 4rem;
}
#aside.toggle-expend-mobile .sidenav .nav li > a{
  padding: 0;
  border-radius: 0;
}
.toggle-expend-mobile ul > li{
  position: relative;
}
.toggle-expend-mobile ul > li.active .nav-sub-mobile{
  position: absolute;
  top: 0px;
  left: 64px;
  padding: 8px 0px;
  background-color: #1e2835;
  min-width: 200px;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
}
.toggle-expend-mobile ul > li.active .nav-sub-mobile li a{
  padding: 0px 20px !important;
}
.toggle-expend-mobile .navbar.lt{
  padding: 8px;
}
.toggle-expend-mobile .sidenav, .toggle-expend-mobile .sidenav .not-expand{
  overflow: visible;
}
.toggle-expend-mobile .sidenav .nav li > a.router-link-active{
  margin: 0;
  border-radius: 0px;
}
.toggle-expend-mobile .sidenav .nav-icon{
  width: 100%;
}
.toggle-expend-mobile .nav-sub .nav-text:before{
  content: none;
}
.toggle-expend-mobile .sidenav .nav li.active > a{
  margin: 0;
}
.toggle-expend-mobile .nav-caret{
  position: absolute;
  right: 8px;
}
.toggle-expend-mobile .sidenav .navbar-brand img{
  width: auto;
}
.sidenav .nav .nav-sub-mobile .nav-text{
  padding: 5px 0px;
}
.toggle-expend-mobile .nav-border .nav > li.active{
  padding: 0px;
}

.import-file-section {
  width: 100%;
}

.mx-datepicker {
  width: 100% !important;
}

.perm-section > div {
  padding-bottom: 30px;
}
.perm-section .col-md-4{
  padding-bottom: 25px;
}
.perm-section .col-md-4 > div{
  padding-bottom: 5px;
}
.perm-section .col-md-4 > div input[type="checkbox"]{
  margin-right: 4px;
}

.tabs-wraper{
  padding: 20px;
}
.tabs-wraper .navbar{
  padding-top: 30px;
  padding-bottom: 0px;
  min-height: auto;
  padding-left: 14px;
  z-index: 9;
}
.tabs-wraper .navbar ul li.nav-item{
  margin-right: 5px !important;
}
.tabs-wraper .navbar ul li a{
  border-radius: 0px;
}
.tabs-wraper .navbar ul li a.accent{
  background-color: #ffffff;
  color: #617283;
  font-weight: 600;
  font-size: 15px;
  border: 1px solid #e0e3e6;
  border-radius: 6px 6px 0px 0px;
}
.tabs-wraper .navbar ul li a.accent:hover{
  -webkit-box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
  box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
  color: #617283 !important;
}
.tabs-wraper .navbar ul li a.router-link-exact-active{
  background-color: #00acc2;
  color: #ffffff;
  border-bottom: none;
  border-color: #00acc2;
}
.tabs-wraper .navbar ul li a.router-link-exact-active:hover,
.tabs-wraper .navbar ul li a.router-link-exact-active:focus{
  background-color: #00acc2;
  color: #ffffff !important;
  box-shadow: none;
}
.tabs-wraper .main-wraper{
  border-radius: 0px 0px 8px 8px;
}

.user-profile .dropdown-menu {
  padding: 8px 0px !important;
  background-color: #232a34;
  border-radius: 6px;
  width: 160px;
}
.user-profile .dropdown-menu .mb-2 {
  margin: 0px !important;
  border: none;
}
.user-profile .dropdown-menu a.dropdown-item {
  padding: 4px 15px;
  color: #aaaaaa;
}
.user-profile .dropdown-menu a.dropdown-item:hover {
  color: #13e5ff;
}

.billing-code-list {
  margin: 0px 15px;
  background-color: #fff;
  padding: 10px 0px;
  border-radius: 0px 0px 8px 8px;
}
.billing-code-list .main-wraper{
  border-radius: 8px;
  margin: 10px 20px 20px 20px;
  box-shadow: none;
}
.billing-fieldset {
  border-radius: 8px !important;
  margin: 15px !important;
}
.billing-fieldset select {
  height: 44px !important;
}

.bottom-buttons{
  padding: 0px 30px;
}

.payer-case-list{
  margin: 0px 15px;
  background-color: #fff;
  padding: 10px 0px;
  border-radius: 0px 0px 8px 8px;
}
.payer-case-list .main-wraper{
  border-radius: 8px;
  margin: 10px 20px 20px 20px;
}

.main-wraper .table-responsive{
  border: 1px solid #f6f7f7;
  border-radius: 8px;
}
.table-wraper {
  padding: 20px;
}

.search-filter {
  padding: 0px 10px;
}
.search-filter .search-field input{
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  padding: 8px;
}

.bank_information {
  padding: 15px 20px 10px 20px !important;
  margin: 25px 15px 10px 15px;
}
.agency_location {
  margin: 8px 15px;
  width: auto;
}

.card{
  background-color: transparent;
  border: none;
}
.card-header{
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  border: none;
}
.card-header ul li{
  margin-right: 5px !important;
}
.card-header ul li a.nav-link{
  background-color: #ffffff;
  color: #617283;
  border-radius: 0px;
  font-weight: 600;
  font-size: 15px;
  border: 1px solid #e0e3e6;
  border-radius: 6px 6px 0px 0px;
}
.card-header ul li a.nav-link:hover{
  -webkit-box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
  box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
}
.card-header ul li a.nav-link.active{
  background-color: #00acc2;
  color: #ffffff;
  border: none;
  border-bottom: 1px solid #00acc2;
}
.card .tab-content{
  background-color: #ffffff;
  -webkit-box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 8px 8px;
}
.table-signature .table-responsive,
.table-word .table-responsive{
  border: 1px solid #e0e3e6;
  border-radius: 8px;
}
.file-form .list-group a{
  color: #00acc2;
}
.file-form .list-group a:hover {
  color: #009db3;
}

.template-info{
  background-color: #ffffff;
  border: 1px solid #e0e3e6;
  border-radius: 8px;
  padding: 0 1.25rem;
}
.template-info .card{
  border-bottom: 1px solid #e0e3e6;
  border-radius: 0px;
}
.template-info .card:last-child{
  border: none;
}
.template-info .card-body{
  padding: 1.25rem 0;
}
.template-info .card-subtitle {
  color: #617283 !important;
  opacity: 1;
  font-weight: 600;
}
.template-info .card-body .card-text a{
  color: #00acc2;
}
.template-info .card-body .card-text a:hover{
  color: #009db3;
}
.comment-section .card-body{
  padding: 0px;
}
.comment-section .card-body > p.card-text{
  border: 1px solid #eaedf0;
  border-radius: 6px;
}
.comment-info{
  padding: 8px 15px;
  background-color: #f3f6f9;
}
.comment-body{
  padding: 8px 15px;
}
.comment-body p:last-child {
  margin-bottom: 0px;
}
.comment-section .quillWrapper .ql-toolbar {
  border-radius: 8px 8px 0px 0px;
}
.comment-section .quillWrapper .ql-container {
  border-radius: 0px 0px 8px 8px;
}
.version-list .card-body{
  border: 1px solid #e0e3e6;
  border-radius: 8px;
  padding: 0px 15px;
  overflow: hidden;
}
.sharing-list{
  border: 1px solid #e0e3e6;
  border-radius: 8px;
  padding: 0px;
  overflow: hidden;
}
.version-header,
.sharing-header{
  background-color: #f3f6f9;
  padding-top: 10px;
  padding-bottom: 5px;
}
.version-list a{
  color: #00acc2;
}
.version-list a:hover{
  color: #009db3;
}
.multiselect__option--highlight,
.multiselect__option--highlight:after{
  background: #00acc2 !important;
}
.multiselect__tag{
  background: #00acc2 !important;
  padding: 6px 26px 6px 10px !important;
}
.history-wraper .card-body{
  padding: 0px;
}
.version-list a.export i{
  color: #00acc2 !important;
}

.cell-width {
  width: 250px;
}

.cell-width {
  width: 250px;
}

.billing-fieldset.service {
  padding: 25px 15px 10px 15px !important;
}

.rounded-corner{
  border-radius: 8px !important;
}

.select-input-tabs ul li.nav-item {
  margin-right: 5px !important;
}
.select-input-tabs ul li a.nav-link{
  background-color: #ffffff;
  color: #617283;
  font-weight: 600;
  font-size: 15px;
  border: 1px solid #e0e3e6;
}
.select-input-tabs ul li a.nav-link:hover {
  -webkit-box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
  box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
  color: #617283 !important;
}
.select-input-tabs ul li a.nav-link.active{
  background-color: #00acc2 !important;
  color: #ffffff !important;
  border-bottom: none;
}
.select-input-tabs .sec-bg{
  border-radius: 0px 0px 8px 8px !important;
}

.calendar-header-font h2{
  font-size: 18px;
  font-weight: 600;
}
.client-name input{
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.client-name input::placeholder{
  color: #adadad;
}

.contact-fieldset {
  border: 1px solid #d6d6d6 !important;
  padding: 10px !important;
  border-radius: 6px !important;
  margin: 15px !important;
}

.reminder-date input{
  border-radius: 4px;
  padding: 6px 10px !important;
  height: auto !important;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #00acc2 !important;
}

.permission-type{
  border: 1px solid #d6d6d6;
  padding-bottom: 10px !important;
  border-radius: 6px;
}
.permission-type > div{
  background-color: #f3f6f9;
  border-bottom: 1px solid #d6d6d6;
  padding: 8px;
  margin-bottom: 5px;
  overflow: hidden;
}

.intake-form-list{
  border: 1px solid #f6f7f7;
  border-radius: 8px;
}
.intake-form-list table{
  margin-bottom: 0px;
}

.edit-user #content > .nav-link,
.staff-signature #content > .nav-link,
.staff-service-list #content > .nav-link,
.staff-service-add #content > .nav-link,
.staff-service-edit #content > .nav-link,
.user-payout #content > .nav-link,
.staff-availability #content > .nav-link{
  display: none;
}

.md-msg-details-body {
  background: #f3f6f9 !important;
}
.md-msg-details-body h6{
  color: #787878 !important;
  font-weight: bold;
}
.msg-subject .text-primary,
.msg-header .text-primary{
  color: #00acc2 !important;
  font-weight: bold;
}

.calendar-header-font h3{
  font-weight: 600;
}
.data-import-select-options table.listView-filter-table{
  width: calc(100% - 1rem);
  overflow-x: scroll;
  display: block;
}

.agency_location {
  margin: 0px !important;
}

.count-info {
  text-align: center;
  padding: 6px 18px;
  border: 1px solid #f6f6f6;
  border-radius: 50px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}
.count-info span {
  margin-right: 10px;
}
.count-info span:last-child{
  margin-right: 0px;
}

.calender-left{
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
}
.calender-right{
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
}
.list-view{
  border-radius: 4px 0px 0px 4px;
}
.grid-view{
  border-radius: 0px 4px 4px 0px;
}
.router-link-active .grid-view,
.router-link-active .list-view{
  background-color: #e0e0e0;
}
.demo-app-calendar .fc-toolbar {
  background-color: #ffffff;
  color: #627384;
  padding: 0px 20px 20px 20px !important;
}
.demo-app-calendar .fc-prev-button,
.demo-app-calendar .fc-next-button{
  border: 1px solid #00acc2;
  padding-top: 3px;
}
.demo-app-calendar .fc-prev-button .fc-icon,
.demo-app-calendar .fc-next-button .fc-icon{
  color: #00acc2;
}
.demo-app-calendar .fc-prev-button:hover,
.demo-app-calendar .fc-next-button:hover{
  background-color: #00acc2;
  border: 1px solid #00acc2;
}
.demo-app-calendar .fc-prev-button:hover .fc-icon,
.demo-app-calendar .fc-next-button:hover .fc-icon{
  color: #ffffff;
}
.demo-app-calendar h2{
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}
.demo-app-calendar .fc-today-button {
  background-color: #00acc2 !important;
  padding: 6px 18px;
  text-transform: capitalize;
}
.demo-app-calendar .fc-dayGrid-view{
  padding: 0px 20px 20px 20px;
}
.demo-app-calendar .fc-head{
  background-color: #f2f7fa;
}
.demo-app-calendar .fc-head .fc-day-header{
  padding: 5px 0px;
}
.demo-app-calendar .fc-body .fc-day-number{
  font-size: 20px;
  margin-right: 8px;
  color: #162738;
}
.demo-app-calendar .fc-today .fc-day-number{
  background-color: #f2f7fa;
}
.demo-app-calendar .fc-event-container a,
.demo-app-calendar .fc-event-container a:hover{
  background-color: #00acc2;
  border-color: #00acc2;
  color: #ffffff;
}
.add-session{
  z-index: 9999 !important;
}

.data-import-select-options .custom-input,
.global-calendar .custom-input{
  height: auto;
  padding: 12px;
}

.header-left .navbar{
  padding-top: 0px;
}
.header-left ul.nav{
  display: block;
  width: 100%;
}
.header-left ul li{
  /* margin-bottom: 4px; */
  margin-bottom: 5px !important;
  margin-right: 0px !important;
}
.header-left ul li a{
  color: #627384;
  background-color: #ffffff;
  border-radius: 6px 0px 0px 6px;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-right: none;
}
.header-left ul li a:hover,
.header-left ul li a:focus{
  color: #ffffff !important;
  background-color: #00acc2 !important;
  border-color: #00acc2;
}
.header-left ul li a.router-link-exact-active,
.header-left ul li a.router-link-exact-active:hover{
  color: #ffffff !important;
  background-color: #00acc2;
  border-color: #00acc2;
}
.details-right{
  border: 1px solid #e0e0e0;
  border-radius: 0px 8px 8px 8px;
  background-color: #ffffff;
}

.popover{
  background: #f2f7fa !important;
}

.session-report-folder-list .border,
.report-files-list .border{
  border-radius: 6px;
}
.session-report-folder-list .list-group-item{
  background: #ffffff;
  padding: 0px;
  overflow: hidden;
}
.session-report-folder-list .list-group-item.active,
.session-report-folder-list .list-group-item.active a:hover{
  background: #00acc2;
  color: #ffffff;
}
.session-report-folder-list .list-group-item a{
  padding: 12px 16px !important;
  display: block;
}
.session-report-folder-list .list-group-item a:hover{
  background: #f9f9f9;
}

.student-availability .billing-fieldset,
.staff-availability .billing-fieldset{
  padding: 8px 30px 0px 30px !important;
}
.availability-row {
  background: #f3f6f9;
  border-radius: 6px;
  padding: 10px 10px 15px 10px;
}
.availability-row label{
  white-space: nowrap;
}
.availability-time{
  color: #00acc2;
  font-weight: 600;
}

.text-primary-active{
  color: #00acc2 !important;
  cursor: pointer;
}

.student-file .details-right .active{
  background-color:#00acc2;
}
.student-file .modal-respon .v--modal-box{
  height: auto !important;
}

.student-health-assessments .med_cont,
.student-health-assessments .health_cont{
  border-radius: 8px;
}

.sub-title{
  color: #00acc2;
  font-size: 15px !important;
  font-weight: 600;
}

.behaviors .tabs-wraper .navbar ul li a.accent{
  border-bottom: none;
}
.behavior-tab-content{
  border: 1px solid #e0e3e6;
  border-radius: 0px 6px 6px 6px;
}

.mx-datepicker-popup {
  z-index: 999999!important;
}

.v--modal-overlay.scrollable{
  z-index: 999999!important;
}

.swal2-container.swal2-shown {
  z-index: 9999999!important;
}

.graph .main-wraper{
  margin-left: 14px;
  margin-right: 14px;
}
.graph-duration .btn-group-toggle{
  display: block;
}
.graph-duration .btn-group-toggle label.btn{
  border-radius: 4px !important;
  margin: 4px 5px 8px 5px;
  padding: 2px 10px !important;
  border-color: #00acc2;
}
.graph-duration .btn-group-toggle label.btn span{
  font-size: 14px;
  font-weight: normal;
  display: block;
  color: #00acc2;
}
.graph-duration .btn-group-toggle label.btn:hover,
.graph-duration .btn-group-toggle label.btn.active{
  background-color: #00acc2;
}
.graph-duration .btn-group-toggle label.btn:hover span,
.graph-duration .btn-group-toggle label.btn.active span{
  color: #ffffff;
}
.graph-intensity{
  text-transform: capitalize;
}
.graph-section{
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.4) !important;
}
.graph-section .btn-green{
  margin: 0px;
  margin-left: 10px;
}
.graph-section .btn-default{
  max-height: 36px;
}

.web-pages-applications .mx-input,
.web-pages-applications .search-filter input,
.auto-generate-session .mx-input,
.session-report .mx-input,
.staff-salary .mx-input,
.payer-plan-add input[type="text"],
.payer-plan-edit input[type="text"],
.appointment-list .mx-input,
.auto-generate-session-import .mx-input,
.auto-generate-session-import .data-import-select-options input,
.mass-goal-import .mx-input,
.invoice-list .mx-input,
.invoice-report .mx-input,
.invoice-list input,
.student .search-filter input,
.delete-student .search-filter input,
.add-student .mx-input,
.add-student input,
.add-skillarea input[type="text"],
.edit-skillarea input[type="text"],
.add-dynamic-session-template .modal-respon input,
.edit-dynamic-session-template .modal-respon input,
.add-goal-activity input[type="text"],
.edit-goal-activity input[type="text"],
.case-document-add input[type="text"],
.case-document-edit input[type="text"],
.global-calendar .sm-goal-list input[type="text"],
.compose input[type="text"],
.add-role input[type="text"],
.edit-role input[type="text"],
.add-parent input[type="text"],
.add-parent input[type="password"],
.edit-parent input[type="text"],
.edit-parent input[type="password"],
.add-user input[type="text"],
.add-user input[type="password"],
.edit-user input[type="text"],
.edit-user input[type="password"],
.edit-student input[type="text"],
.edit-student .mx-input,
.add-student-funder input[type="text"],
.edit-student-funder input[type="text"],
.student-program-goal input[type="text"],
.add-behaviors input[type="text"],
.edit-behaviors input[type="text"],
.file-management input[type="text"],
.view-folder-contents input[type="text"],
.shared-folders input[type="text"],
.shared-files input[type="text"],
.add-location input[type="text"],
.edit-location input[type="text"],
.add-service input[type="text"],
.edit-service input[type="text"],
.sheets-link input[type="text"]{
  height: 44px;
}
.export-invoice-modal select,
.add-student select,
.add-skillarea select,
.edit-skillarea select,
.add-dynamic-session-template .modal-respon select,
.edit-dynamic-session-template .modal-respon select,
.compose select,
.add-user select,
.edit-user select,
.edit-student select,
.add-student-funder select,
.edit-student-funder select{
  height: 44px !important;
}
.add-student input[type="checkbox"]{
  height: inherit;
}

.templates .download-link{
  margin-left: 10px;
}
.templates table i{
  color: #00acc2 !important;
}

.appointment-list .add-filter .bv-no-focus-ring{
  max-height: 230px;
}
.auto-generate-session-import .add-filter .bv-no-focus-ring{
  max-height: 600px;
}
.invoice-list .add-filter .bv-no-focus-ring{
  max-height: 400px;
}
.appointment-list .add-filter .bv-no-focus-ring .custom-checkbox,
.auto-generate-session-import .bv-no-focus-ring .custom-checkbox,
.invoice-list .add-filter .bv-no-focus-ring .custom-checkbox{
  width: 50%;
  display: inline-block;
}

.global-calendar .custom-checkbox-wrapper{
  line-height: 20px;
}

.auto-generate-session .table-data{
  width: auto !important;
}

.graph-list ul{
  width: 100%;
}
.graph-list ul li{
  list-style-type: none;
  display: inline-block;
  margin: 4px 0px;
}

.merge-field .table-signature span.active {
  background-color: #00acc2;
  color: #ffffff;
  padding: 3px 10px;
  border-radius: 4px;
}

.table-pagination #per-page-select{
  max-width: 150px;
}
.table-pagination label{
  width: auto !important;
}

.data-import-select-options .status{
  display: inline-block;
  margin: 0px 5px;
}
.data-import-select-options .status i{
  color: #00acc2;
}

.payer-cases .payer-case-list .main-wraper .box{
  border-radius: 8px;
}
.payer-cases .payer-case-list .main-wraper{
  box-shadow: none;
}

.appointment-list .table-wraper td{
  position: relative;
}
.appointment-list .table-wraper td .status{
  position: absolute;
  top: 12px;
  left: -7px;
}

.sm-note-helper #accordion{
  background-color: #fcfcfc;
  border: 1px solid #dddddd !important;
  color: #00acc2;
  font-weight: 600;
  border-radius: 6px;
}
.sm-note-helper #accordion .list-group-item-primary {
  color: inherit;
  background-color: transparent;
}
.sm-note-helper #accordion .list-group-item{
  text-transform: capitalize;
  color: rgba(70, 90, 110, 0.85);
}
.sm-note-helper #accordion .card{
  overflow: hidden;
}
.sm-note-helper #accordion .card:hover{
  cursor: pointer;
}
.sm-note-helper #accordion .card .collapse.show{
  background-color: #ffffff;
}
.sm-sn-body .form-check-input{
  margin-left: -1.8rem !important;
}
.sm-note-helper #accordion .sm-sn-header > div{
  position: relative;
}
.sm-note-helper #accordion .sm-sn-header > div:after{
  content: "▲";
  position: absolute;
  right: 10px;
  top: 10px;
  color: rgba(70, 90, 110, 0.85);
  transform: scale(1,0.8);
  opacity: 0.6;
}
.sm-note-helper #accordion .sm-sn-header > div.collapsed:after{
  content: "▼";
  color: rgba(70, 90, 110, 0.85);
  transform: scale(1,0.8);
  opacity: 0.6;
}
.sm-note-helper #accordion .sm-sn-body .sm-sn-header > div:after{
  opacity: 0.6;
}

.app{
  display: block;
}
//.app-content{
//  float: right;
//  width: calc(100vw - 14rem);
//}
.fixed {
  position: fixed;
  left: 50%;
  margin-left: -50%;
}

.student .text-truncate {
  height: 60px;
}
.student .text-truncate:hover {
  overflow: visible;
  height: auto;
  white-space: break-spaces;
  cursor: default;
}

#main-content.app-content{
  height: 100%;
  min-height: 100vh;
  /* position: relative; */
}
#content-footer {
  width: calc(100% - 13rem);
  display: block;
  float: right;
}
#content-main {
  margin-bottom: 40px;
}

.graph-no-data {
  background-color: #f3f6f9;
  padding: 60px 0;
  border-radius: 6px;
}
.graph-no-data p{
  font-weight: 600;
  color: rgba(70, 90, 110, 0.5);
  font-size: 20px;
}

.activity-list h5{
  color: #00acc2;
  font-size: 18px;
}
.activity-list a.list-group-item{
  background-color: #f3f6f9;
}

.edit-dynamic-session-template .scheduler-border .single-bg {
  background-color: #f3f6f9;
}

.global-calendar .sm-goal-list thead{
  background-color: #f3f6f9;
}
.global-calendar .sm-goal-list thead tr,
.appointment-list .sm-goal-list thead tr{
  width: 100%;
  display: inline-table;
}
.global-calendar .sm-goal-list .multiselect__content-wrapper{
  bottom: auto;
}
.global-calendar .sm-goal-list .badge-primary{
  background-color: #00acc2;
}

.student-skill-data-entry input[type="number"]{
  padding-right: 4px;
}
.student-skill-data-entry .floating-menu .bg-primary{
  background-color: #00acc2 !important;
  border-radius: 4px;
}
.student-skill-data-entry .float-section .list-group-item{
  background: #00acc2;
}
.student-skill-data-entry .float-section .list-group-item:hover{
  background-color: rgba(0, 0, 0, 0.05);
}
.student-skill-data-entry .target-section .card{
  margin-bottom: 6px;
}
.student-skill-data-entry .target-section .card .btn-primary{
  background-color: #00acc2;
  border-color: #00acc2;
}
.student-skill-data-entry .target-section .card .btn-primary:hover{
  background-color: #009ab0;
  border-color: #009ab0;
}

.template-info{
  border: none;
}
.file-management-wraper .template-info.left{
  margin-top: 54px;
  border: 1px solid #e0e3e6;
}
.file-management-wraper .template-info.left .card-body{
  padding: 10px 0px;
}
.file-management-wraper .template-info.left .card-body .card-text{
  color: #00acc2;
}
.file-management-wraper .template-info.left .card-body .folder-tree:last-child{
  border: none;
}
.file-management-wraper .template-info.right{
  padding: 0px;
  border: 1px solid #e0e3e6;
}
.file-management-wraper .template-info.right .card-body{
  padding: 0px;
}
.file-management-wraper .template-info.right .card-subtitle{
  padding: 1.3rem 1rem 1rem 1rem;
  border-bottom: 1px solid hsla(210,8%,51%,.13);
}
.file-management-wraper .template-info.right .table-responsive {
  border: 1px solid #f6f7f7;
  border-radius: 8px;
}

.button-elipsis.b-dropdown .btn-secondary{
  background-color: #00acc2;
  border-color: #00acc2;
  padding: 0.46rem 0.8rem;
  height: 35px;
  margin-top: 3px;
}
.button-elipsis.b-dropdown .dropdown-menu{
  border-radius: 6px;
}
.button-elipsis.b-dropdown .dropdown-menu a.dropdown-item:hover{
  color: #000000;
}

.ctx-menu-container{
  border: none !important;
}
.ctx-menu{
  min-width: 180px !important;
}
.ctx-menu .context-option{
  padding: 3px 20px;
  color: rgba(70, 90, 110, 0.85);
}
.ctx-menu .context-option:hover{
  background-color: rgba(0, 0, 0, 0.05);
  color: #000000;
}
.ctx-menu .context-option i{
  margin-left: -0.5rem;
  margin-right: .5rem;
}
.ctx-menu .context-option{
  cursor: pointer;
}

.student-activity-history .timeline-item .dot{
  left: -12px !important;
}

.student-file .main-wraper .padding,
.student-folder-contents .main-wraper .padding,
.student-active-component .main-wraper .padding{
  padding: 15px 0 0 0;
}
.view-folder-contents .btn-back,
.shared-folders .btn-back,
.shared-files .btn-back,
.student-folder-contents .btn-back,
.student-active-component .btn-back{
  display: inherit;
  max-height: 36px;
}
.view-folder-contents .btn-back i,
.shared-folders .btn-back i,
.shared-files .btn-back i,
.student-folder-contents .btn-back i,
.student-active-component .btn-back i{
  margin-top: 4px;
  margin-right: 4px;
}

.b-table-sticky-column span {
  width: 40px;
}

.graph-custom {
  border: 1px solid #e4e6e8;
  border-radius: 6px;
}

.edit-dynamic-session-template a.btn-green {
  color: #fff;
}

.skill_data-session_date .datetimepicker .header-picker{
  background-color: #00acc2 !important;
}
.skill_data-session_date .datetimepicker .datepicker-container .datepicker-days .datepicker-day-effect,
.skill_data-session_date .datetimepicker .datepicker-container .datepicker-days .datepicker-day .datepicker-today{
  width: 20px;
  height: 20px;
}
.student-skill-data-entry .floating-menu{
  z-index: 9999;
}

.content-header .navbar {
  background-color: #2899a9;
}
.sidenav .nav.bg{
  padding-top: 0px;
  background-color: #161d29;
}
.sidenav .nav li > a{
  padding: 0.2rem 1.25rem;
  border-top: 1px solid #161d29;
  background-color: #253145;
}
.sidenav .nav-icon,
.sidenav .nav-text,
.nav-caret{
  color: #ffffff;
  opacity: 1;
}
.nav-border .nav > li.active{
  padding: 0px;
}
.sidenav .nav li > a.router-link-active{
  margin: 0px;
  border-radius: 0px;
}
.sidenav .nav li.active > a{
  padding: 0.2rem 1.25rem;
  margin: 0px;
  border-radius: 0px;
}
.sidenav .nav li > a.router-link-active{
  padding: 0.2rem 1.25rem;
}
.sidenav .nav li.active .nav-sub a.router-link-active .nav-text {
  color: #00acc2;
}
.sidenav .nav li > a:hover, .sidenav .nav li > a:focus {
  color: #ffffff;
  background-color: #00acc2;
}
.sidenav a:hover > .nav-text {
  color: #ffffff;
}
.nav-sub .nav-text:before{
  top: 10px;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  margin-right: 5px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.import-click {
  pointer-events: none;
  opacity: 0.5;
}

@media(min-width:991px) and (max-width:2000px){
  /* #content.web-pages-applications,
  #content.invoice-list,
  #content.auto-generate-session-import,
  #content.mass-goal-import,
  #content.appointment-list {
      width: calc(100% - 13rem);
  } */
  #main-content.app-content{
    width: calc(100% - 13rem);
  }

  .toggle-btn a{
    display: none;
  }
}

@media(min-width:991px){
  .app-aside {
    display: inline-block !important;
    background-color: #232a34;
  }
}

@media(max-width:990px){
  .toggle-btn a {
    display: none;
  }
  .modal-respon .v--modal-box{
    width: 100% !important;
    left: auto !important;
    right: auto !important;
  }
  .count-info span {
    margin-right: 0px;
    display: block;
  }

  .app-content {
    float: left;
    width: 100%;
  }

  #content-footer {
    width: 100%;
  }
}

@media(max-width:768px){
  .breadcrumb {
    float: left;
    padding-left: 0px;
    padding-bottom: 5px !important;
  }
  .padding {
    padding: 1rem 0rem;
  }
  .bank_information{
    margin: 25px 10px 10px 10px;
  }
  .agency_location{
    margin: 8px;
  }
  .template-info {
    margin-bottom: 20px;
  }
  .edit-user .tabs-wraper .navbar ul,
  .staff-signature .tabs-wraper .navbar ul,
  .staff-service-list .tabs-wraper .navbar ul,
  .staff-service-add .tabs-wraper .navbar ul,
  .staff-service-edit .tabs-wraper .navbar ul,
  .user-payout .tabs-wraper .navbar ul,
  .staff-availability .tabs-wraper .navbar ul {
    width: 100%;
  }
  .edit-user .tabs-wraper .navbar ul li.nav-item,
  .staff-signature .tabs-wraper .navbar ul li.nav-item,
  .staff-service-list .tabs-wraper .navbar ul li.nav-item,
  .staff-service-add .tabs-wraper .navbar ul li.nav-item,
  .staff-service-edit .tabs-wraper .navbar ul li.nav-item,
  .user-payout .tabs-wraper .navbar ul li.nav-item,
  .staff-availability .tabs-wraper .navbar ul li.nav-item {
    margin-right: 0px !important;
    width: 100%;
  }
  .edit-user .tabs-wraper .navbar ul li a.accent,
  .staff-signature .tabs-wraper .navbar ul li a.accent,
  .staff-service-list .tabs-wraper .navbar ul li a.accent,
  .staff-service-add .tabs-wraper .navbar ul li a.accent,
  .staff-service-edit .tabs-wraper .navbar ul li a.accent,
  .user-payout .tabs-wraper .navbar ul li a.accent,
  .staff-availability .tabs-wraper .navbar ul li a.accent{
    font-size: 13px;
  }

  .header-left .navbar ul,
  .graph-list ul {
    height: 45px;
    padding: 0 10px;
    text-align: left;
    border: 1px solid #e0e0e0;
    background: #ffffff;
    border-radius: 6px;
    margin-right: 4px;
  }
  .header-left .navbar ul li {
    display: block;
    position: relative;
  }
  .graph-list ul li{
    display: block;
    position: relative;
    margin: 0px;
  }
  .header-left .navbar ul li:first-child:before {
    /* position: absolute; */
    content: " Student Information ";
    position: relative;
    font-size: 16px;
    line-height: 42px;
  }
  .graph-list ul li:first-child:before{
    content: " Skill Graph List ";
    position: relative;
    font-size: 16px;
    line-height: 42px;
    padding-left: 10px;
  }
  .header-left .navbar ul li:first-child:after,
  .graph-list ul li:first-child:after {
    position: absolute;
    top: 10px;
    right: 0;
    font-family: FontAwesome;
    font-size: 16px;
    content: "";
    color: #999999;
    padding-right: 10px;
  }
  .header-left .navbar a,
  .graph-list a {
    display: none;
    font-size: 14px;
    text-decoration: none;
    color: #627384;
  }
  .graph-list a{
    font-size: 15px;
  }
  .graph-list a.btn.primary:hover{
    color: #ffffff !important;
  }
  .graph-list a.active{
    background-color: #00acc2;
    color: #ffffff;
  }
  .header-left .navbar ul li,
  .graph-list ul li {
    border: none;
  }

  .header-left .navbar.nav-is-visible ul,
  .graph-list.nav-is-visible ul {
    height: initial;
    background: #ffffff;
    padding-bottom: 4px;
  }
  .header-left .navbar.nav-is-visible ul li:first-child:after,
  .graph-list.nav-is-visible ul li:first-child:after {
    content: "";
  }
  .header-left .navbar.nav-is-visible ul li a,
  .graph-list.nav-is-visible ul li a {
    display: block;
    border-radius: 6px;
    border-right: 1px solid #e0e0e0;
  }
  .graph-list.nav-is-visible ul li a.btn-clear-filter{
    margin: 10px !important;
  }
  .header-left .navbar.nav-is-visible ul li:last-child,
  .graph-list.nav-is-visible ul li:last-child {
    margin-bottom: 10px;
  }

  .details-right{
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 8px;
  }
  .graph-section{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .file-management-wraper .template-info.left{
    margin-top: 20px;
  }
}

@media(max-width:560px){
  .tabs-wraper .navbar ul {
    width: 100%;
  }
  .tabs-wraper .navbar ul li.nav-item {
    margin-right: 0px !important;
    width: 100%;
  }
  .tabs-wraper .navbar ul li a.accent{
    font-size: 13px;
  }
  .edit-user .tabs-wraper,
  .staff-signature .tabs-wraper,
  .staff-service-list .tabs-wraper,
  .staff-service-add .tabs-wraper,
  .staff-service-edit .tabs-wraper,
  .user-payout .tabs-wraper,
  .staff-availability .tabs-wraper{
    padding-left: 10px;
    padding-right: 10px;
  }
  .count-info{
    padding: 10px 18px;
  }

  .case-document-details .sm-note-helper .radio-content-box{
    width: 100%;
  }
}

@media(max-width:480px){
  .card-header ul li a.nav-link{
    font-size: 13px;
    padding: 8px;
  }
  .demo-app-calendar h2 {
    font-size: 14px !important;
  }
  .demo-app-calendar .fc-body .fc-day-number {
    font-size: 14px;
    margin-right: 4px;
  }

  .details-right {
    width: 96%;
    margin: 0 auto;
  }
  .edit-student-authorization .fieldset{
    padding: 6px 6px 12px 6px;
  }

  .graph-section .btn-green{
    margin-top: 10px;
  }

  .appointment-list .add-filter .bv-no-focus-ring .custom-checkbox,
  .auto-generate-session-import .add-filter .bv-no-focus-ring .custom-checkbox,
  .invoice-list .add-filter .bv-no-focus-ring .custom-checkbox{
    width: 100%;
  }
  .appointment-list .add-filter .bv-no-focus-ring,
  .auto-generate-session-import .add-filter .bv-no-focus-ring,
  .invoice-list .add-filter .bv-no-focus-ring{
    max-height: none;
  }
}