.red { background-color: #f44336; color: $light-white; }
.pink { background-color: #e91e63; color: $light-white; }
.purple { background-color: #9c27b0; color: $light-white; }
.deep-purple { background-color: #673ab7; color: $light-white; }
.indigo { background-color: #3f51b5; color: $light-white; }
.blue { background-color: #2196f3; color: $light-white; }
.light-blue { background-color: #03a9f4; color: $light-white; }
.cyan { background-color: #00bcd4; color: $light-white; }
.teal { background-color: #009688; color: $light-white; }
.green { background-color: #4caf50; color: $light-white; }
.light-green { background-color: #8bc34a; color: $light-black; }
.lime { background-color: #cddc39; color: $light-black; }
.yellow { background-color: #ffeb3b; color: $light-black; }
.amber { background-color: #ffc107; color: $light-black; }
.orange { background-color: #ff9800; color: $light-black; }
.deep-orange { background-color: #ff5722; color: $light-white; }
.brown { background-color: #795548; color: $light-white; }
.blue-grey{ background-color: #607d8b; color: $light-white; }
.grey { background-color: #9e9e9e; color: $light-white; }
