/*
Buttons 
*/
@mixin btn-outline($color, $color-color){
	&:hover,
	&:focus,
	&.active{
		color: $color-color !important;
		background-color: darken( $color, 7.5% );
	}
	&:focus,
	&:active{
		box-shadow: 0 0 0 3px rgba($color, .5);
	}
}

.btn{
	font-size: $font-size;
	font-weight: 500;
	color: inherit;
	outline: 0!important;
	&:not([disabled]):hover,
	&:not([disabled]):focus,
	&:not([disabled]).active{
		color: inherit;
		box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
	}
	> i{
		&.float-left,
		&.float-right{
			line-height: inherit;
		}
	}
}

.btn-lg{
	font-size: 1.25rem;
}

.btn-md{
	padding: .4695rem .75rem;
	font-size: 0.9rem;
}

.btn-sm{
	
}

.btn-xs{
	padding: .2195rem .5rem;
	font-size: 0.8rem;
	line-height: 1;
}

.btn-rounded{
	border-radius: 4rem;
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	&.btn-xs{
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}
}

.btn-outline{
	border-width: 1px;
	background-color: transparent;
}

.btn{
	&.b-primary,
	&.primary{
		@include btn-outline($primary, $primary-color);
	}
	&.b-accent,
	&.accent{
		@include btn-outline($accent, $accent-color);
	}
	&.b-warn,
	&.warn{
		@include btn-outline($warn, $warn-color);
	}
	&.b-success,
	&.success{
		@include btn-outline($success, $success-color);
	}
	&.b-info,
	&.info{
		@include btn-outline($info, $info-color);
	}
	&.b-warning,
	&.warning{
		@include btn-outline($warning, $warning-color);
	}
	&.b-danger,
	&.danger{
		@include btn-outline($danger, $danger-color);
	}
	&.b-dark,
	&.dark{
		@include btn-outline($dark, $dark-color);
	}
	&.b-white{
		@include btn-outline($white, $dark-black);
	}
	&.b-light{
		@include btn-outline($light, $light-color);
	}
}

.btn-icon{
	text-align: center;
	padding: 0 !important;
	width: 2.375rem;
	height: 2.375rem;
	line-height: 2.375rem;
	position: relative;
	overflow: hidden;
	i{
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		display:flex;
		-ms-flex-pack: center;
	    justify-content: center;
	    -ms-flex-align: center;
	    align-items: center;
		border-radius: inherit;
		@include transition(all 0.3s);

	}
	&.btn-xs{
		width: 1.5rem;
		height: 1.5rem;
		line-height: 1.5rem;
	}
	&.btn-sm{
		width: 1.775rem;
		height: 1.775rem;
		line-height: 1.775rem;
	}
	&.btn-lg{
		width: 3.167rem;
		height: 3.167rem;
		line-height: 3.167rem;
	}
	&.btn-social{
		i:last-child{
			top: 100%;
		}
	}
	&.btn-social:hover,
	&.btn-social:focus,
	&.btn-social.active{
		i:first-child{
			top: -100%;
		}
		i:last-child{
			top: 0;
			color: #fff;
		}
	}
	&.btn-social-colored{
		i:first-child{
			color: #fff;
		}
	}
}

.btn.white,
.btn.btn-default,
.btn.btn-secondary{
	border-color: $input-border;
}

.btn-default{
	background-color: #fff;
	color: $dark-black;
}

.btn-groups .btn{
  margin-bottom: .25rem;
}

.btn-fw{
	min-width: 7rem;
}
