.input-editor {
  .tox-tinymce {
    border-width: 1px;
    border-color: rgba(120, 130, 140, 0.2);
    border-radius: 0.25rem;
  }
  .tox {
    .tox-toolbar-overlord {
      .tox-toolbar__primary {
        background-image: none;
      }
    }
    &:not(.tox-tinymce-inline) {
      .tox-editor-header {
        box-shadow: none;
        border-bottom: 1px solid rgba(120, 130, 140, 0.2);
      }
    }
  }
}