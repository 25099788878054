.display-break-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  .display-bl--primary {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -3px;
    margin-right: -3px;
    .display-bl--item {
      padding-left: 3px;
      padding-right: 3px;
      & > span {
        display: block;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 1px 10px;
        background: $theme-primary-color;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
      }
    }
  }
  .display-bl--secondary {
    margin-left: 5px;
    .display-bl--secondary-trigger{
      border: none;
      outline: none;
      padding: 0 10px;
      background: #fff;
      font-size: 12px;
      font-weight: 600;
      border-radius: 4px;
      box-shadow: 0 0 1px 1px rgba(#000, 0.2);
    }
  }
  &.has-primary-list-only {
    .display-bl--primary {
      .display-bl--item > span {
        max-width: 100%;
      }
    }
  }
}
.b-popover-display-bl--secondary-body {
  background: #000 !important;
  .arrow {
    &:after {
      border-right-color: #000;
    }
  }
  .display-bl--secondary-list {
    color: #fff;
    padding-left: 15px;
    font-size: 13px;
    font-weight: 500;
  }
}
