.data-table-with-soft-delete {
  .tabs-area {
    .nav {
      .nav-item {
        margin-bottom: 0;
        &:last-of-type {
          margin-left: 5px;
        }
        .nav-link {
          border-color: #d7d7d7;
          border-bottom-color: #f9f9f9;
          border-bottom-width: 3px;
          font-weight: 600;
          background-color:#f9f9f9;
          &.active {
            background-color:#fff !important;
            border-bottom-color: #00acc2;
          }
        }
      }
    }

  }
  .content-area {
    width: 100%;
    .main-content-area{
      &:not(.with-filter) {
        flex-basis: 100%;
      }
      &.with-filter {
        flex-basis: calc(100% - 300px);
      }
    }
    .filter-content-area {
      flex-basis: 300px;
      padding-left: 5px;
      .filter-form-card {
        border: 1px solid #e9e9e9;
        & > .filter-header {
          padding: 0.6rem 0.75rem;
          border-bottom: 1px solid #e9e9e9;
          height: 49px;
          .filter-title {
            font-size: 1.2rem;
            font-weight: 600;
          }
        }
        select {
          font-size: inherit;
        }
      }
    }
  }
}
/* ------------------ Filter collapse ------------ */
.filter-collapse {
  padding: 0 8px;
  .filter-header {
    padding: 8px 0;
    &, * {
      cursor: pointer;
      user-select: none;
    }
  }
  .filter-content {
    padding-bottom: 8px;
  }
  .filter-header .filter-label{
    font-weight: 600;
  }
}

/* ------------------ Filter input checkbox panel ------------ */
.input-checkbox-panel {
  .panel-area {
    max-height: 150px;
    border: 1px solid rgba(120, 130, 140, 0.2);
    padding: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
  }
}
