.input-avatar {
  & > .avatar-validation-area {
    position: relative;
    & > .avatar-container-area {
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 120px;
      overflow: hidden;
      & > .preview-avatar {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 120px;
        border: 1px solid #c9c9c9;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      & > .file-browser-area {
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(#000, 0.6);
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 80px;
        border-bottom-right-radius: 80px;
        & > .input-avatar-input-field {
          display: flex;
          margin: 0;
          overflow: hidden;
          position: relative;
          bottom: 0;
          left: 0;
          z-index: 3;
          width: 24px;
          height: 24px;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          line-height: 1;
          color: #fff;
          font-size: 1rem;
          & > input[type=file] {
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

    }
  }
}
