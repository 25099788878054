.empty-card {

  .empty-row-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .empty-row-icon {
      .empty-row-icon-holder {
        .icon{
          font-size: 4rem;
          color: #c9c9c9;
        }
      }
    }
    .empty-row-body {
      padding-top: 2rem;
      .empty-row-header{
        .empty-row-title {
          font-weight: 600;
          font-size: 18px;
        }
      }
      .empty-row-sub-header {
        .empty-row-sub-title {
          color: #a5a5a5;
          font-weight: 600;
        }
      }
    }
  }
  &.with-border {
    border: 1px solid #ebebeb;
    border-radius: 4px;
  }
  &.size-sm {
    padding: 0.8rem;
    min-height: 200px;
  }
  &.size-md {
    padding: 1.5rem;
    min-height: 350px;
  }
  &.size-lg {
    padding: 3.6rem;
    min-height: 466px;
  }
}
