.page-create-card {
  padding: 30px 100px 10px;
  & > .page-create-card-header {
    line-height: 1;
    margin-bottom: 30px;
    & > .page-create-card-header--title {
      font-weight: 600;
      font-size: 1.6rem;
      color: #515151;
    }
    & > .page-create-card-header--sub-title {
      margin-top: 5px;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }
  &:not(.without-background) > .page-create-card--body {
    background: #fff;
    padding: 25px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }
}