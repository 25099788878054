.section-card {
  & > .section-card-header {
    .section-card-header--title {
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    .section-card-header--sub-title {
      font-size: 0.8rem;
      font-weight: 500;
      color: #a7a7a7;
    }
  }
  //& > .section-card-body {
  //  padding-left: 15px;
  //  padding-right: 15px;
  //}
}