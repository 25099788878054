/*
Form 
*/
.form-control{
  border-color: $input-border;
  border-radius: $input-border-radius;
  color: inherit;
  &:focus{
    color: inherit;
    box-shadow: 0 0 0 0.2rem rgba($primary,.25);
  }
  &:not(.is-valid):not(.is-invalid):focus{
    border-color: $input-border-focus;
  }
}

.form-control,
.col-form-legend,
.input-group-addon{
  font-size: $font-size;
}

.form-control-lg{
  font-size: 1.25rem;
}

.custom-file-control,
.custom-file-control:before,
.custom-select{
  border-color: $input-border;
}

.custom-select,
.custom-file-control,
.custom-file-control:before,
select.form-control:not([size]):not([multiple]):not(.form-control-lg):not(.form-control-sm){
  height: calc(#{$font-size * $line-height} + #{$input-btn-padding-y * 2} + 2px);
}

.form-control::-webkit-input-placeholder{
  color: inherit;
  opacity: 0.3;
}
.form-control::-moz-placeholder{
  color: inherit;
  opacity: 0.3;
}
.form-control:-ms-input-placeholder{
  color: inherit;
  opacity: 0.3;
}

.form-control-spin{
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}

.input-group-text,
.custom-file-label:after{
  font-size: inherit;
  border-color: $input-border !important;
  background-color: $border-color-lt;
}


.form-group-stack{
  .form-control{
    margin-top: -1px;
    position: relative;
    border-radius: 0;
    &:focus,
    &:active{
      z-index: 1;
    }
  }
}

.form-file{
  position: relative;
  input{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.input-group-sm > .form-control{
  min-height: 1.95rem;
}

.active-checked{
  opacity: 0;
}

input:checked + .active-checked{
  opacity: 1;
}
