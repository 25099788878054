.permission-denied {
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .pd-status-code {
    font-size: 5.2rem;
    font-weight: 900;
    color: rgba(#000, 0.3);
  }
  .pd-message-title {
    font-size: 1.8rem;
    font-weight: 900;
    color: #000;
  }
  .pd-message-body {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }
}