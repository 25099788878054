.input-availability {
  .input-availability--layout-days {
    & > .input-availability--layout-days-header {
      label {
        font-weight: 600;
        font-size: 16px;
      }
      p {
        font-weight: 500;
        color: #b1b1b1;
      }
    }
    & > .input-week-days {
      .input-week-day-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -5px;
        margin-right: -5px;
        & > .input-week-day {
          label {
            position: relative;
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
            & > input[type=checkbox],
            & > input[type=radio] {
              position: absolute;
              opacity: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
            & > span.button-area {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 45px;
              height: 35px;
              border-radius: 4px;
              border: 1px solid #dddddd;
              font-weight: 600;
              background: #fff;
            }
            & > input[type=checkbox]:checked ~ span.button-area,
            & > input[type=radio]:checked ~ span.button-area {
              background: $theme-primary-color;
              color: #fff;
              border-color: #1290a3;
            }
          }
        }
      }

    }
  }
  .input-availability--layout-times {
    & > .input-availability--layout-times-header {
      padding-top: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      & > ._header-days {
        width: 120px;
      }
      & > ._header-times {
        font-weight: 600;
      }
    }
    & > .input-week-day-times {
      .input-week-day-item-with-time {
        & + .input-week-day-item-with-time {
          margin-top: 5px;
        }
        .input-weekdays-with-times {
          & > .week-day-details {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            & > .week-day-name {
              padding: 5px;
              font-weight: 500;
              width: 120px;
              position: relative;
              z-index: 2;
            }
            & > .week-day-times {
              & > .week-day-time {
                & > .week-day-time--validator {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding-top: 3px;
                  padding-bottom: 3px;
                  & > .input-weekday-with-times {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    background: #f5f5f5;
                    border-radius: 4px;
                    padding: 5px 2px;
                    border: 1px solid #cfcfcf;
                    & > .date-selector-input {
                      padding-left: 5px;
                      padding-right: 5px;
                      .input-field--time {
                        height: 34px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        &.is-invalid {
                          input[type=text].mx-input {
                            border-color: #dc3545 !important;
                          }
                        }
                      }
                    }
                    & > .date-selector-separator {
                      font-weight: 500;
                      & > .input-field--separator {
                        height: 34px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      }
                    }
                  }
                  & > .week-day-time-action {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 5px;
                    padding-right: 5px;
                    & > .week-day-time-action--btn {
                      padding-left: 5px;
                      padding-right: 5px;
                      button {
                        border: 1px solid #cfcfcf;
                        box-shadow: 0 0 1px 1px rgba(0,0,0,0.123);
                        outline: none;
                        background: #f5f5f5;
                        font-size: 16px;
                        width: 30px;
                        height: 30px;
                        border-radius: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
