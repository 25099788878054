/*
Progress 
*/
.progress[value]::-webkit-progress-bar{
  background-color: $min-black;
}

.progress {
	border-radius: .25rem;
	overflow: hidden;
	background-color: $min-black;
}

.progress-bar {
    display: inline-block;
    float: left;
    height: 100%;
    border-radius: .25rem;
    text-align: center;
    background-color: #0074d9;
    font-size: 0.8em;
    -webkit-transition: width .6s ease;
    transition: width .6s ease;
}
