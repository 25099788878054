.app-auth-layout {
  box-sizing: border-box;
  padding-left: 0;
  transition: padding-left 0.5s ease-in-out;
  display:block;
  .background-gray-light {
    background-color: #6c757d3b !important;
    margin-top: 10px;
  }
  .app-sidenav {
    position: fixed;
    width: 220px;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #424242;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    & > .app-sidenav-brand {
      background-color: $theme-primary-color;
      width: 100%;
      height: 60px;
      overflow: hidden;
      .app-sidenav-brand-img {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #9ce2eb;
        & > img {
          width: auto;
          height: 35px;
          object-fit: contain;
        }
      }
    }
    .app-sidenav-menu-scroll-area {
      position: relative;
      height: calc(100vh - 140px);
      overflow-y: scroll;
    }
    .app-sidenav-menu {
      height: 100%;
      display: flex;
      flex-direction: column;
      list-style: none;
      padding:0;
      margin:0;
      & > .menu-item {
        & > .menu-link-item {
          position: relative;
          & > .menu-link-item-action {
            & > .menu-link {
              display: flex;
              flex-direction: row;
              align-items: center;
              user-select: none;
              padding: 10px 24px 10px 10px;
              color: #fff;
              font-size: 13px;
              cursor: pointer;
              border-bottom: 1px solid transparent;
              &:hover,
              &.active {
                background-color:$theme-primary-color;
              }
              & > .menu-item-icon {
                flex:0;
                display: block;
                width: 30px;
                padding-right: 16px;
                font-size: 14px;
                & > .menu-icon {
                  display: block;
                  width: 18px;
                }
              }
              & > .menu-item-label {
                flex: 1 1 0;
                width: calc(100% - 60px);
                overflow: hidden;
                text-overflow: ellipsis;
                & > .menu-label {
                  font-weight: 500;
                }
              }
            }
          }
          & > .menu-link-item-suffix {
            position: absolute;
            right: 0;
            top: 0;
            color: rgba(#fff, 0.6);
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 18px;
            pointer-events: none;
          }
          &.open {
            & > .menu-link:not(.active):not(:hover) {
              border-bottom-color: rgba(255, 255, 255, 0.12);
            }
          }
        }
        & + .menu-item {
          border-top: 1px solid #000;
        }
        &:not(:last-of-type) {
          border-bottom: 1px solid #5e5e5e;
        }
        & > .menu-item-children {
          margin: 0;
          display: none;
          flex-direction: column;
          list-style: none;
          padding-top: 5px;
          padding-bottom: 5px;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          //background-color: #000;
          //box-shadow: 0 1px 0 0 rgba(255,255,255,0.3) inset;
          & > .menu-sub-item {
            & > .menu-sub-item-link {
              color: #fff;
              padding: 6px 0;
              display: block;
              font-weight: 500;
              &:hover,
              &.active {
                color: $theme-primary-color
              }
            }
          }
        }
        & > .menu-link-item.open + .menu-item-children {
          display: flex;
          opacity: 1;
        }
      }
    }
    & > .app-sidenav-footer {
      height: 80px;
      border-top: 1px solid rgba(#FFF, 0.3);
      background-color: #616161;
      padding: 8px;
      & > .help-desk-menu {
        & > .help-desk-btn {
          width: 100%;
          display: block;
        }
      }
      & > .footer-text {
        color: #dcdcdc;
      }
    }
  }
  &.with-sidenav {
    padding-left: 220px;
    .app-sidenav {
      transform: translateX(0);
    }
  }
  .app-content {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  .app-content-main-area {
    flex: 1 0 auto;
    .app-mix-header {
      .app-primary-header {
        height: 60px;
        button.toggle-sidenav {
          border: none;
          outline: none;
          background-color: transparent;
        }
      }
      .auth-secondary-header {
        .search-box-global {
          flex: 1 0;
        }
        .browser-history-action {
          .history-nav-menu {
            height: 43px;
            padding-right: 10px;
            button.history-nav-btn {
              background-color: transparent;
              outline: none;
              height: 25px;
              border: 1px solid #d7d7d7;
              color: #919191;
              border-radius: 4px;
              font-weight: 600;
              padding-left: 12px;
              padding-right: 12px;
              transition: all 0.3s ease-in-out;
              &:not(:disabled):hover {
                border-color: #424242;
                color: #424242;
              }
              &:disabled {
                opacity: 0.6;
              }
            }
          }
        }
        .mini-dropdown-create {
          display: block;
          position: relative;
          z-index: 400;
          & > .mini-dropdown-trigger {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 30px;
            color: #fff;
            border:none;
            background: $theme-primary-color;
            border-radius: 4px;
            font-weight: 500;
            padding: 0 10px 0 20px;
            line-height: 1;
            transition: all 0.3s ease-in-out;
            & > .mini-dropdown-trigger-icon {
              height: 100%;
              background: transparent;
              width: 24px;
              font-size: 16px;
              color: #fff;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            & > .mini-dropdown-trigger-text {
              padding-right: 5px;
              text-space: 1;
            }
          }
          & > .mini-dropdown-menu {
            position: absolute;
            top: 100%;
            margin-top: 6px;
            right: 0;
            background: #fff;
            min-width: 240px;
            max-width: 330px;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            box-shadow: 0 1px 1px #d1d1d1, 0 0 18px 5px rgba(#000,0.125);
            padding: 5px;
            &:after,
            &:before {
              position: absolute;
              bottom: 100%;
              content: "";
              border: 6px solid transparent;
              right: 8px;
            }
            &:before {
              border-bottom-color: #ebebeb;
              margin-bottom: 1px;
            }
            &:after {
              border-bottom-color: #fff;
            }
            .mini-dropdown-menu--wrap {
              max-height: 600px;
              overflow-y: auto;
              .mini-dropdown-menu--item {
                display: block;
                padding: 8px 12px;
                transition: background-color 0.3s ease-in-out;
                border-radius: 4px;
                & + .mini-dropdown-menu--item {
                  border-top: 1px solid #f5f5f5;
                }
                & > .mini-dropdown-menu-item-wrap {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  & > .mini-dropdown-menu--item-icon {
                    width: 40px;
                    font-size: 16px;
                    opacity: 0.6;
                  }
                  & > .mini-dropdown-menu--item--details {
                    width: calc(100% - 40px);
                    & > .mini-dropdown-menu--item-label {
                      font-size: 14px;
                      font-weight: 600;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1.2;
                    }
                    & > .mini-dropdown-menu--item-desc {
                      font-size: 12px;
                      font-weight: 500;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      color: #929292;
                    }
                  }
                }
                &:hover {
                  background-color: #009cb3;
                  & > .mini-dropdown-menu-item-wrap {
                    color: #fff;
                    & > .mini-dropdown-menu--item--details {
                      & > .mini-dropdown-menu--item-desc {
                        color: rgba(#fff, 0.6);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .app-footer {

    }
  }
}
