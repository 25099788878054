/*
Boxed Layout
*/
@include media-breakpoint-down(lg) {
	body.container{
		max-width: 100%;
		padding-left:  0;
		padding-right: 0;
	}
}

$w_lg: 71.25rem;
@include media-breakpoint-up(lg) {
	body.container{
		&{
			padding-left: 0;
			padding-right: 0;
		}
		@include box-shadow(0 1px 1px rgba(0,0,0,0.1));

		.app-header{
			left: auto;
			right: auto;
			width: $w_lg;
		}
	}
}
