.signature-selection-area {
  .signature-selection {
    & > .signature-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #d7d7d7;
      background: #f8f8f8;
      border-radius: 4px;
      & > .signature-preview {
        width: 50%;
        padding: 5px;
        & > .sp--image {
          background: #fff;
          border-radius: 4px;
          box-shadow: inset 0 0 0 1px rgba(#000,0.124);
          padding: 5px;
          & > img {
            width: 100%;
            height: 40px;
            object-fit: contain;
          }
        }
        & > .sp--image-empty {
          padding: 5px;
          & > .empty-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 40px;
            font-weight: 600;
          }
        }
      }
      & > .signature-action {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-left: 5px;
        padding-right: 5px;
        & > .empty-action {
          font-weight: 600;
        }
        & > .input-file-btn {
          position: relative;
          & > input[type=file] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}