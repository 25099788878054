.search {
    position: relative;
    width: 100%;
    border: 1px solid #e9e9e9;
  
    a {
      text-decoration: none;
    }
  
    button {
      float: right;
      border: none;
      background-color: transparent;
      border-radius: 0px;
      cursor: pointer;
    }
  
    .search-input {
      width: 100%;
      padding: 10px;
      height: 44px;
      position: relative;
    }
  
    .search-select {
      position: relative;
  
      &:after {
        content: "\25BC";
        color: rgba(70, 90, 110, 0.85);
        transform: scale(1, 0.8);
        opacity: 0.6;
        position: absolute;
        right: 15px;
        top: -2px;
      }
    }
  }
  
  .toc-odd {
    position: absolute;
    background: #fafafa;
    width: 100%;
    border: 1px solid #999;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    height: 200px;
    padding: 0px;
    z-index: 9;
  
    li {
      padding: 8px;
      border-bottom: 1px solid #dddddd;
      display: flex;
      justify-content: space-between;
  
      &:last-child {
        border-bottom: none;
      }
  
      i {
        color: #ff0000;
        cursor: pointer;
      }
    }
  }
  
  .search .add-option {
    background-color: #00acc2;
    color: #ffffff;
    border-radius: 4px;
    padding: 6px 15px;
  
    &:hover {
      background-color: #209eaf;
    }
  }
  
  input[type=search] {
    border: transparent;
    border-radius: 4px;
    padding: .5em;
    transition: all 2s ease-in;
    width: 245px;
  }
  
  .search .sel-option li a.current {
    color: #00acc2 !important;
  }
  
  .selected-main {
    padding: 2px;
  
    .element-selected {
      background-color: #209eaf;
      border-radius: 4px;
      padding: 2px;
      margin-right: 2px;
      color: white;
      position: relative;
    }
  
    .s-delete {
      padding: 2px;
    }

    .selcted-count {
      background-color: #14baa8;
      border-radius: 8px;
      padding: 4px;
      margin-right: 2px;
      color: white;
    }
  }

  .count-list {
    text-align: left;
    position: absolute;
    background: #ffffff;
    z-index: 999;
    color: #14baa8;
    padding: 5px;
    border: 1px solid #ccc;
    width: 200px;
    margin: 0 auto;
    // transform: translate(-50%, 0);
  }
  
  .count-list li {
    list-style-type: none;
  }
  
  .extra-element-selected {
    margin-bottom: 5px;
  }
  .multiselect__tags {
    max-height: 33.19px !important;
    padding: 3px 40px 0 8px !important;
    min-height: 33.19px !important;
  }
  .multiselect__placeholder {
      margin-bottom: 4px !important;
  }
  .multiselect__select {
      height: 33.19px !important;
  }