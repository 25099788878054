.avatar-image {
  & > .avatar-image-container {
    overflow: hidden;
    background-color: #000;
    border: 1px solid #c5c5c5;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &.size-xs {
    & > .avatar-image-container {
      width: 16px;
      height: 16px;
      border-radius: 16px;
    }
  }
  &.size-sm {
    & > .avatar-image-container {
      width: 35px;
      height: 35px;
      border-radius: 35px;
    }
  }
  &.size-md {
    & > .avatar-image-container {
      width: 60px;
      height: 60px;
      border-radius: 35px;
    }
  }
  &.size-xl {
    & > .avatar-image-container {
      width: 120px;
      height: 120px;
      border-radius: 120px;
    }
  }
}