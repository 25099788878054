/*
Box
*/
.box,
.box-color{
	background-color: #fff;
	position: relative;
	margin-bottom: 1.5rem;
}

.box-header{
	position: relative;
	padding: 1rem;
	h2,
	h3,
	h4{
		margin: 0;
		font-size: 18px;
		line-height: 1;
	}
	h3{
		font-size: 16px;
	}
	h4{
		font-size: 15px;
	}
	small{
		display: block;
		margin-top: 4px;
		opacity: 0.6;
	}
}

.box-body{
	padding: 1rem;
}

.box-footer{
	padding: 1rem;
}

.box-divider{
	border-bottom:1px solid $border-color;
	margin: 0 16px;
	height: 0;
}

.box-tool{
	position: absolute;
	right: 16px;
	top: 14px
}

.box-shadow-x{
	@include box-shadow(1px 0 1px rgba(0, 0, 0, 0.05), -1px 0 1px rgba(0, 0, 0, 0.05));
}

.box-shadow-y{
	@include box-shadow(0 1px 1px rgba(0, 0, 0, 0.05), 0 -1px 1px rgba(0, 0, 0, 0.05));
}

.box-shadow {
	&,
  	.box,
  	.box-color{
		@include box-shadow(0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03));
	}
}
.box-shadow-0 {
	&,
  	.box,
  	.box-color{
		@include box-shadow(0 0px 1px rgba(0,0,0,0.15));
	}
}
.box-shadow-1 {
	&,
  	.box,
  	.box-color{
  		@include box-shadow(0 1px 2px rgba(0,0,0,0.1),0 -1px 0px rgba(0,0,0,0.02));
	}
}
.box-shadow-2 {
	&,
  	.box,
  	.box-color{
		@include box-shadow(0 1px 2px rgba(0,0,0,0.15),0 -1px 0px rgba(0,0,0,0.02));
	}
}
.box-shadow-3 {
	&,
  	.box,
  	.box-color{
		@include box-shadow(0 2px 4px rgba(0,0,0,0.1),0 -1px 0px rgba(0,0,0,0.02));
	}
}
.box-shadow-4 {
	&,
  	.box,
  	.box-color{
		@include box-shadow(0 2px 15px rgba(0,0,0,0.05), 0 -1px 0px rgba(0,0,0,0.01));
	}
}
.box-shadow-5 {
	&,
  	.box,
  	.box-color{
		@include box-shadow(0 3px 20px rgba(0,0,0,0.05), 0 -1px 0px rgba(0,0,0,0.01));
	}
}

.box-shadow-6 {
	&,
  	.box,
  	.box-color{
		@include box-shadow(0 2px 5px rgba(0, 0, 0, 0.26), 0 -1px 0px rgba(0, 0, 0, 0.02));
	}
}


.box-radius-1 {
	&,
  	.box,
  	.box-color{
		border-radius: 1px;
	}
	.box-header{
		border-radius: 1px 1px 0 0;
	}
	.box-footer{
		border-radius: 0 0 1px 1px;
	}
}
.box-radius-2 {
	&,
  	.box,
  	.box-color{
		border-radius: 2px;
	}
	.box-header{
		border-radius: 2px 2px 0 0;
	}
	.box-footer{
		border-radius: 0 0 2px 2px;
	}
}
.box-radius-3 {
	&,
  	.box,
  	.box-color{
		border-radius: 3px;
	}
	.box-header{
		border-radius: 3px 3px 0 0;
	}
	.box-footer{
		border-radius: 0 0 3px 3px;
	}
}

.box-radius-4 {
	&,
  	.box,
  	.box-color{
		border-radius: 4px;
	}
	.box-header{
		border-radius: 4px 4px 0 0;
	}
	.box-footer{
		border-radius: 0 0 4px 4px;
	}
}

.box-radius-5 {
	&,
  	.box,
  	.box-color{
		border-radius: 5px;
	}
	.box-header{
		border-radius: 5px 5px 0 0;
	}
	.box-footer{
		border-radius: 0 0 5px 5px;
	}
}
