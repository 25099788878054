@mixin student-input-tag-item($avatar_size, $padding_btw_avatar_and_info, $padding_info_right) {
  .st--item {
    display: flex;
    flex-direction: row;
    align-items: center;
    .st--avatar {
      width: $avatar_size;
      .st--avatar-holder {
        width: $avatar_size;
        height: $avatar_size;
        overflow: hidden;
        border-radius: $avatar_size;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .st--info {
      flex: 0;
      width: calc(100% - #{$avatar_size});
      padding-left: $padding_btw_avatar_and_info;
      padding-right: $padding_info_right;
      .st--info-name {
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .st--info-secondary {
        font-size: 12px;
        font-weight: 500;
        color: #959595;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@mixin input-tag-dropdown() {
  &.variant-floating {
    position: absolute;
  }
  &.variant-static {
    position: relative;
  }
  z-index: 5;
  left: 0;
  width: 100%;
  background: #fff;
  border-left: 1px solid rgba(120, 130, 140, 0.2);
  border-right: 1px solid rgba(120, 130, 140, 0.2);
  border-bottom: 1px solid rgba(120, 130, 140, 0.2);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  &.position-top{top: 40px;}
}
.input-tag-v2 {
  position: relative;
  .input-tag-input-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    border: 1px solid rgba(120, 130, 140, 0.2);
    height: 40px;
    border-radius: 4px;
    & > .input-only {
      flex: 1;
      position: relative;
      & > .input-icon-search,
      & > .input-action-clear{
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: 20px;
      }
      & > .input-icon-search {
        left: 0;
        margin-left: 8px;
        opacity: 0.6;
        font-size: 13px;
        pointer-events: none;
      }
      & > .input-action-clear {
        right: 0;
        margin-right: 5px;
        border: none;
        outline: none;
        background: transparent;
      }
      input[type=text],
      input[type=search] {
        padding-left: 30px;
        padding-right: 20px;
        height: 40px;
        outline: none;
        border: none;
        background: transparent;
        width: 100%;
      }
    }
    & > .input-appended {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > .input-icon-status {
        width: 20px;
      }
      & > .input-filter-button {
        button {
          outline: none;
          background: transparent;
          border: none;
          width: 32px;
          &.active {
            color: $theme-primary-color;
          }
        }
      }
    }
    & > .input-single-selected-options {
      width: 100%;
      padding: 0 5px;
      & > .input-tag-selected-item {
        border: 1px solid #ebebeb;
        border-radius: 4px;
        padding: 3px 10px;
      }
    }
  }
  .input-filter-area {
    z-index: 3;
    @include input-tag-dropdown();
    // Student filter area
    .st--filter-area-action {
      padding: 5px;
    }
    .form-area {
      form {
        & > .form-body {
          max-height: 250px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 5px;
        }
        & > .form-footer {
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
  .input-tag-dropdown {
    @include input-tag-dropdown();
    &.position-top {
      position: static !important;
    }
    .input-tag-options-menu-infinite {
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      & > .input-tag-max-limit-warning {
        padding: 0 10px;
        background: #fbfbfb;
        font-weight: 600;
        font-size: 12px;
        border-bottom: 1px solid rgba(120, 130, 140, 0.2);
      }
      & > .input-tag-option-menu {
        height: 100%;
        .input-dropdown-item {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 10px;
          cursor: pointer;
          user-select: none;
          transition: all 0.5ms ease-in-out;
          font-weight: 500;
          &.disabled {
            opacity: 0.8;
          }
          &:not(.selected):not(:hover) {
            box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.085);
          }
          &.selected {
            background: #f1f1f1;
          }
          &:not(.disabled):hover {
            background: #f5f5f5;
          }
          & > ._item--select {
            pointer-events: none;
            .pretty {
              .state label:before,
              .state label:after{
                border-radius: 15%;
                border-width: 2px;
              }
              .state label:after {
                border-color: #000;
              }
              input:checked ~ .state.p-success label:after,
              &.p-toggle .state.p-success label:after{
                background-color: transparent !important;
              }
              input:checked ~ .state.p-success .icon,
              input:checked ~ .state.p-success .svg,
              &.p-toggle .state.p-success .icon,
              &.p-toggle .state.p-success .svg {
                color: #000;
                stroke: #000;
              }
            }
          }
          & > ._item--data {
            @include student-input-tag-item(35px, 15px, 5px)
          }
        }
      }
    }

  }
  .input-tag-options-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 8px;
    & > .input-option-loading-text {
      padding-left: 10px;
      font-weight: 500;
      user-select: none;
    }
  }
  .input-tag-options-empty {
    padding: 5px 8px;
    font-weight: 500;
    user-select: none;
  }
  .input-tag-load-more-area {
    .input-btn-load-more {
      border: none;
      outline: none;
      width: 100%;
      background: transparent;
      border-top: 1px solid rgba(120, 130, 140, 0.2);
      padding: 5px 14px;
      font-weight: 500;
    }
  }
  .input-multi-selected-options {
    padding: 5px 0;
    .input-multiple-visible-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: -2px;
      margin-right: -2px;
      flex-wrap: wrap;
      & > .input-tag-selected-item {
        flex: none;
        &.item-col-12 { width: 100%; }
        &.item-col-6 { width: 50%; }
        &.item-col-3 { width: 33.33%; }
        &.item-col-4 { width: 25%; }
        padding: 2px 2px;
        .display-tag {
          padding: 2px 3px 2px 2px;
          border: 1px solid #d5d5d5;
          background: #fff;
          & > .default-text {
            padding-left: 6px;
          }
        }
      }
      & > .additional-hidden {
        padding: 2px 2px;
        .dropdown-toggle {
          background: #fff;
          padding: 3px 8px;
          border: 1px solid #d5d5d5;
          border-radius: 25px;
          color: $theme-primary-color;
          font-weight: 600;
          & > .input-multiple-counter {
          }
        }
        .input-hidden-dropdown-item {
          padding: 4px 10px;
          user-select: none;
        }
      }
    }

  }
  .input-tag-selected-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    .display-tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 25px;
      width: 100%;
      .display-label {
        width: calc(100% - 20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        @include student-input-tag-item(25px, 5px, 5px)
      }
      .action-delete {
        width: 20px;
        & > button {
          outline: none;
          border: none;
          background: transparent;
        }
      }
    }
  }
}
