/*
Base 
*/

html{
  background: darken($body-bg, 5%);
}
body {
  color: $text-color;
  font-size: $font-size;
  line-height: $line-height;
  background-color: $body-bg;
}

*:focus {
  outline: 0 !important;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
}
a:hover,
a:focus {
  color: $hover-color;
  text-decoration: none;
}

button{
  color: inherit;
}

pre {
  color: inherit;
  background-color: $light;
  border-color: $border-color;
}

code{
  background-color: $border-color;
}

blockquote{
  border-color: $border-color;
}

small{
  font-size: 90%;
}

sup{
  position: relative; 
  top: -1em; 
  font-size: 75%
}

canvas{
  max-width: 100%;
}
