.input-signature {
  & > .input-signature-canvas-area {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    width: 100%;
    height: 400px;
    background-color: #fcfcfc;
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #ddd;
    &.active{
      box-shadow: 0 0 1px 1px #000;
    }
  }
  .canvas-area {
    position: relative;
    flex: 1;
    canvas.input-canvas {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}