/*
Plugins
*/
// setting
.setting{
  z-index: 1000;
  position: fixed;
  top: 120px;
  right: -220px;
  width: 220px;
  @include transition(right 0.2s ease);
  border: 1px solid rgba(120,120,120,0.1);
  &.active{
    right: -2px;
    z-index: 1060;
  }
}
.setting > .toggle{
  position: absolute;
  left: -42px;
  top: -1px;
  padding: 10px 15px;
  z-index:1045;
  border: 1px solid rgba(120,120,120,0.1);
  border-right-width: 0;
  background-clip: padding-box;
  border-radius: 24px 0 0 24px;
  background: #fff;
}

// icon list
.list-icon i{
  margin-right: 16px
}
.list-icon div{
  line-height: 40px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

i[class*="ion-"],
i[class*=" ion-"]{
  font-size: 18px;
  vertical-align: -2px;
}
i[class*="s-l-i"],
i[class*=" s-l-i"]{
  font-size: 16px;
  vertical-align: -2px;
}

// browser
.browser{
  position: relative;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding-top: 26px;
  border-radius: 4px;
  overflow: hidden;
  .browser-body:before,
  &:before{
    content: '';
    border-color: rgba(120,130,140,0.2);
    border-top-style: dotted;
    border-top-width: 6px;
    position: absolute;
    left: 10px;
    top: 10px;
    height: 8px;
    width: 30px;
  }
  &:after{
    content: '';
    border-color: rgba(120,130,140,0.1);
    border-top-width: 1px;
    border-top-style: solid;
    position: absolute;
    left: 0;
    right: 0;
    top: 26px;
    height: 1px;
  }
}

// fullcalendar
.fc-toolbar{
  background-color: $primary;
  color: $primary-color;
  border-radius: 4px 4px 0 0;
  margin: 0 !important;
  padding: 6px 0;
  h2{
    font-size: 16px;
    padding-top: 6px;
  }
  button{
    color: $primary-color;
    background: transparent;
    border: none;
    box-shadow: none;
    margin-top: 3px;
  }
}
.fc-event{
  border-width: 0;
  padding: 3px 8px;
  color: inherit;
  background: transparent;
  border-radius: 10px;
}
.fc-event:hover{
  color: inherit;
}
.fc td,
.fc tr,
.fc th{
  border-color: $border-color;
}
.fc-day-number{
  font-size: 0.8em;
  opacity: 0.5;
}
td.fc-today{
  background: transparent !important;
}
.fc-today .fc-day-number{
  background: $primary;
  color: $primary-color;
  border-radius: 0 0 0 4px;
  opacity: 1;
  padding: 0px 3px;
}

/*JQVmap*/
div.jqvmap-zoomin, 
div.jqvmap-zoomout{
  width: 18px;
  height: 18px;
  background-color: rgba(255,255,255,0.1);
  color: inherit;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/*Charts*/
.jqstooltip{
  background-color: rgba(0,0,0,0.8) !important;
  border: solid 1px #000 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px !important;
  @include box-sizing(content-box);
}

// easypie
.easypiechart {
  position: relative;
  text-align: center;
  canvas{
    vertical-align: middle;
  }
  > div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

// peity
.peity{
  font-size: 0;
}

// select
.dark .btn-default.form-control{
  background: transparent !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: inherit !important;
}
.ui-select-bootstrap > .ui-select-choices{
  overflow-y: scroll;
}

// dataTable
.dataTables_wrapper{
  padding: 0;
}
.table.dataTable{
  border-collapse: collapse !important;
  width: 100% !important;
}

// bootstrap table
.bootstrap-table{
  .table,
  .table>thead>tr>th,
  .fixed-table-footer{
    border-color: $border-color-lt;
  }
  .fixed-table-container{
    border-width: 0;
  }
  tbody .selected td{
    background-color: $min-black;
  }
  .fixed-table-toolbar,
  .fixed-table-pagination{
    padding: 0 1rem;
  }
}

// summernote
.note-editor{
  border-width: 0 !important;
  overflow: visible !important;
  background-color: inherit;
}
.note-editor .note-editing-area .note-editable,
.note-editor .note-statusbar{
  background-color: transparent !important;
  color: inherit !important;
}
.note-toolbar{
  border-width: 0;
}

// select2
.select2-container--default {
  .select2-selection {
    min-height: 33px;
    border-color: $input-border !important;
    .dark &{
      background-color: lighten( $dark, $color-dk-percent + 1.5% );
    }
    .select2-selection__rendered{
      color: inherit;
      padding-left: 9px;
    }
    .select2-selection__arrow{
      top: 3px;
    }
    .select2-selection__choice{
      background: transparent;
      border-color: $input-border;
      line-height: 1.5;
    }
    .select2-selection__choice__remove{
      color: inherit !important;
    }
  }
  .select2-selection--single{
    .select2-selection__rendered{
      line-height: 30px;
    }
  }
  .select2-search--inline {
    .select2-search__field{
      margin-top: 6px;
    }
  }
  .select2-search__field{
    color: inherit;
  }
  .select2-search--dropdown{
    .select2-search__field{
      border-color: $input-border;
    }
  }
  .select2-dropdown{
    color: $dark-black;
    border-color: $input-border;
  }
}

// datepicker
.bootstrap-datetimepicker-widget.dropdown-menu{
    width: auto;
    .picker-switch td span{
      margin: 0;
    }
}
.datepicker-inline{
  width: auto;
}
.datepicker{
  padding: 0;
  font-size: 0.7rem;
  &.dropdown-menu{
    padding: 4px;
  }
  table{
    tr{
      td span.active,
      td.active,
      td.selected,
      td.today{
        &,
        &:hover,
        &:active{
          background-image: none;
          color: $primary-color;
          background-color: $primary !important;
        }
      }
    }
    td,
    th{
      height: auto;
      line-height: 1.5 !important;
      border-width: 0;
      vertical-align: middle;
      padding: 4px !important;
      span:not(.active),
      &:not(.active):hover{
        background-color: transparent;
      }
      span{
        height: auto !important;
        line-height: 2 !important;
      }
    }
    .disabled{
      opacity: 0.4;
    }
  }
}
.timepicker{
  a[data-action]{
    padding: 0;
  }
  table{
    td,
    td span{
      height: 30px;
      width: 48px;
      line-height: 30px;
      padding: 0;
    }
  }
  .separator{
    width: 36px;
    padding: 0;
  }
}
// notie
.notie-container{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1050;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  padding: 0.875rem 1.5rem;
  background-color: $dark;
  color: $full-white;
  border-radius: 2px;
  max-width: 35rem;
  min-width: 18rem;
  .btn{
    padding: 0px 6px;
    line-height: 18px;
    cursor: pointer;
    box-shadow: none !important;
  }
  .btn:empty{
    display: none;
  }
}
.notie-textbox{
  -ms-flex: 1;
  flex: 1;
  margin-right: 1.5rem;
}
.notie-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(255,255,255,0);
}
