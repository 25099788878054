.inner-layout {
  .inner-layout-navbar {
    ul.nav {
      li {
        margin-right: 1px;
        margin-bottom: 3px;
        & > .router-link-exact-active {
          color: rgba(255, 255, 255, 0.85);
          background-color: #2899a9;;
        }
      }
    }
  }
  .inner-layout-content-area {
    padding: 1.5rem;
  }
}
