.role-card {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 1px 1px rgba(#000, 0.09);
  border-radius: 4px;
  position: relative;
  & > .role-card-header {
    padding: 12px;
    border-bottom: 1px solid rgba(#000, 0.12);
    & > .role-card--title {
      font-size: 14px;
      font-weight: 600;
    }
    & > .role-card--sub-title {
      font-weight: 500;
      color: #7e7e7ecc;
    }
  }
  & > .role-card-body {
    height: 250px;
    & > .role-card-groups {
      height: 100%;
      & > .role-card-groups-header {
        font-weight: 600;
        margin-bottom: 4px;
        height: 30px;
        font-size: 12px;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #a7a7a7;
      }
      & > .role-card-groups-body {
        height: calc(100% - 30px);
        overflow-y: auto;
        overflow-x: hidden;
        & > .role-card-groups--item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 15px;
          padding-right: 15px;
          & > .role-card-groups--item-serial {
           font-weight: 900;
          }
          & > .role-card-groups--item-name {
            padding-left: 5px;
            font-weight: 500;
          }
        }
      }
    }
    & > .role-card-empty-group {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      & > .role-card-empty-text {
        & > .empty-icon {
          position: relative;
          width: 50px;
          height: 50px;
          font-size: 32px;
          opacity: 1.3;
          background-color: #e1e1e1;
          border-radius: 50px;
          color: #fff;
          margin-left: auto;
          margin-right: auto;
          overflow: hidden;
          & > i.fa {
            position: absolute;
            top: 19px;
            left: 15px;
          }
        }
        & > .empty-title {
          font-weight: 500;
          max-width: 150px;
          margin-top: 17px;
          color: #b5b5b5;
        }
      }
    }
  }
  & > .role-card-action {
    padding: 12px;
    & > .role-card-action-waring,
    & > .role-card-action-edit {
      height: 34px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    & > .role-card-action-waring {
      font-weight: 500;
      color: #c5c5c5;
      text-align: center;
    }
    & > .role-card-action-edit {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .action-btn {
        height: 32px;
        border: none;
        outline: none;
        background-color: transparent;
        font-weight: 500;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 4px;
        &:hover {
          background-color: rgba(#000, 0.08);
        }
        &:disabled {
          opacity: 0.6;
          cursor: none;
        }
      }
    }
  }
}
.user-title-card {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 1px 1px rgba(#000, 0.09);
  border-radius: 4px;
  position: relative;
  & > .user-title-card-header {
    padding: 12px;
    border-bottom: 1px solid rgba(#000, 0.12);
    & > .user-title-card--title {
      font-weight: 600;
      font-size: 14px;
    }
    & > .user-title-card--sub-title {
      font-weight: 500;
      color: #a3a3a3;
    }
  }
  & > .user-title-card-body {
    padding-top: 10px;
    padding-bottom: 10px;
    & > .user-title-card-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 3px 15px;
      & > .info-title {
        flex-basis: 60px;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 11px;
      }
      & > .info-desc {
        a  {
          color: $theme-primary-color;
          font-weight: 500;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  & > .user-title-card-footer {
    padding: 15px;
    height: 40px;
    .warning-text {
      font-weight: 500;
      color: #c5c5c5;
      text-align: center;
    }
    .action-btn {
      height: 32px;
      border: none;
      outline: none;
      background-color: transparent;
      font-weight: 500;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 4px;
      &:hover {
        background-color: rgba(#000, 0.08);
      }
      &:disabled {
        opacity: 0.6;
        cursor: none;
      }
    }
  }
}
.permission-module-layout {
  .permission-module-layout-nav {
    background: #fff;
    & > .permission-module-layout-nav-menu {
      & > ul.nav {
        & > li.nav-item {
          & > a.nav-link {
            border: 1px solid rgba(#000, 0.12);
            border-radius: 4px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            & > .nav-item-title {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            & > .nav-item-description {
              font-size: 11px;
              color: #999999;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &.active {
              color: rgba(255, 255, 255, 0.85);
              background-color: #2899a9;
              & > .nav-item-description {
                color: rgba(#fff, 0.3);
              }
            }
            &:hover {
              background-color: rgba(#000, 0.08);
              & > .nav-item-title {
                color: #000;
              }
              & > .nav-item-description {
                color: #999999;
              }
            }
          }
          & + li.nav-item {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .permission-module-layout-content {
    & > .permission-module-layout-setting {
      .setting-layout + .setting-layout {
        margin-top: 1.2rem;
      }
    }
  }
  .setting-layout {
    box-shadow: 0 0 1px 1px rgba(#000, 0.12);
    border-radius: 4px;
    padding: 1.4rem;
    & > .setting-layout-header {
      margin-bottom: 1.2rem;
      & > .setting-layout-header--title {
        font-weight: 600;
        font-size: 14px;
      }
      & > .setting-layout-header--description {
        color: #ababab;
        font-size: 12px;
      }
    }
  }
}
.permission-block {
  width: 100%;
  user-select: none;
  &.state-disabled {
    & > .permission-block-body {
      .permission-block-switch{
        opacity: 0.7;
      }
    }
  }
  & > .permission-block-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    & > .permission-module-heading {
      background-color: $theme-primary-color;
      color: #fff;
      padding: 0.2rem 0.5rem;
      text-transform: uppercase;
      font-size: 11px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-weight: 600;
    }
  }
  & > .permission-block-body {
    padding: 10px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.10), 0 0 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background-color: #fff;
    .permission-block-switch {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      & > .permission-header {
        margin-right: auto;
        & > .permission-header--title {
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          & > .type {
            text-transform: uppercase;
            font-size: 0.6rem;
            padding: 3px;
            border-radius: 4px;
            line-height: 1;
            margin-right: 5px;
            font-weight: 700;
            letter-spacing: 1px;
            border: 1px solid rgba(#000, 0.3);
            &.type-read {
              border-color: $success;
              color: $success;
            }
            &.type-write {
              border-color: $danger;
              color: $danger;
            }
          }
          & > .title {}
        }
        & > .permission-header--desc {
          font-weight: 600;
          color: #ababab;
        }
      }
      & > .permission-actions {
        margin-left: auto;
        & > .choose-permission-section {
          display: flex;
          flex-direction: row;
          align-items: center;
          & > .status {
            margin-right: 5px;
            font-weight: 600;
          }
        }
      }
    }
    & > .permission-block-warnings {
      color: $danger;
      font-weight: 600;
      font-size: 12px;
      padding-top: 8px;
      //& > .warning-message {
      //  padding: 2px 10px;
      //}
    }
  }
  & + .permission-block {
    margin-top: 0.8rem;
  }
}
.permission-modules {
  .switch-modules-all {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px solid rgba(120, 130, 140, 0.2);
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    text-transform: uppercase;
    & > .--title {
      font-weight: 600;
    }
    & > .--switch {
      margin-left: 15px;
    }
  }
  .permission-module {
    & > .permission-module--details {
      border: 1px solid rgba(120, 130, 140, 0.2);
      border-radius: 4px;
      & > .permission-module--header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 10px;
        background-color: #f1f1f1;
        & > .permission-module--headings {
          margin-right: auto;
          & > .permission-module--title {
            font-weight: 600;
            font-size: 14px;
          }
          & > .permission-module--sub-title {

          }
        }
        & > .permission-module--action {
          margin-left: auto;
        }
      }
      & > .permission-module--collapse {
        .permission-module--permissions {
          height: 300px;
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          .permission-item {
            padding: 10px;
            & > .permission-item--body {
              display: flex;
              flex-direction: row;
              align-items: center;
              .permission-item--header {
                margin-right: auto;
                width: calc(100% - 50px);
                padding-right: 10px;
                .permission-item--header-title {
                  font-weight: 600;
                }
                .permission-item--header-desc {
                  font-size: 12px;
                  color: #979797;
                }
              }
              .permission-item--action {
                margin-left: auto;
                width: 50px;
              }
            }
            &:hover {
              background-color: rgba(#000, 0.10);
            }
          }
        }
      }
    }
  }
}
.permission-condition {
  background-color: #f1f1f1;
  padding: 0.5rem;
  border:1px solid rgba(#000, 0.12);
  border-radius: 4px;
  & > .permission-group {
    padding: 1.2rem;
  }
}