.app-alert-toast {
  position: fixed;
  top: 35px;
  left:0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1111;
  & > .app-alert-toast--content {
    pointer-events: all;
    min-width: 150px;
    max-width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 18px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid transparent;
    &.success {
      background: #45ae45;
      color: #fff;
      box-shadow: 0 0 4px 3px rgba(#fff, 0.12);
    }
    &.info {
      background: #009daa;
      color: #fff;
      box-shadow: 0 0 4px 3px rgba(#fff, 0.12);
    }
    &.warning {
      background: #d9aa1e;
      color: #fff;
      box-shadow: 0 0 4px 3px rgba(#fff, 0.12);
    }
    &.error {
      background: $danger;
      color: #fff;
      box-shadow: 0 0 4px 3px rgba(#fff, 0.12);
    }
    &.default {
      background: #fff;
      color: #000;
      box-shadow: 0 0 4px 3px rgba(#000, 0.12);
    }
    .toast-message {
      padding-right: 10px;
    }
    .toast-action {
      margin-left: auto;
      button {
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        font-size: 18px;
      }
    }
  }
}