input[type=text].form-control,
input[type=number].form-control,
input[type=password].form-control,
input[type=email].form-control,
input[type=url].form-control,
input[type=search].form-control,
input[type=tel].form-control,
input[type=date].form-control,
select.custom-select,
select.form-control{
  height: 40px;
  font-weight: 500;
  font-size: 0.8rem;
}

.custom-control-checkbox {
  height: 100%;
  font-weight: 500;
  font-size: 0.8rem;
}

.input-label {
  .input-custom-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    & > .text {
      font-weight: 600;
      font-size: 0.85rem;
    }
    & > .is-required {
      color: $danger;
      font-size: 0.55rem;
      margin-left: 5px;
    }
    & > .is-optional {
      color: #919191;
      margin-left: 5px;
      font-weight: 500;
    }
  }
}
.col-form-label {
  font-weight: 500;
}
.input-password {
  position: relative;
  & > .input-password-meter {
    position: absolute;
    right: 45px;
    top: 0;
    z-index: 4;
    font-size: 10px;
    height: 40px;
    display: none;
    flex-direction: row;
    align-items: center;
    padding: 0 2px 0 2px;
    max-width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    text-transform: uppercase;
    pointer-events: none;
    .input-password-meter-score-bar {
      display: none;
    }
    .input-password-meter-score {
      background: transparent;
      padding: 3px 5px;
      border-radius: 4px;
    }
  }
  &.with-score {
    input[type=password] {
      padding-right: 85px;
    }
    & > .input-password-meter {
      display: flex;
    }
    &.score-0 > .input-password-meter,
    &.score-1 > .input-password-meter{
      & > .input-password-meter-score {
        background: $danger;
        color: #fff;
      }

    }
    &.score-2 > .input-password-meter {
      & > .input-password-meter-score {
        background: $warn;
        color: #fff;
      }

    }
    &.score-3 > .input-password-meter,
    &.score-4 > .input-password-meter {
      & > .input-password-meter-score {
        background: $success;
        color: #fff;
      }
    }
  }
}
.input-switch-group {
  .btn-group-toggle {
    & > label.btn-app-theme {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      &:before,
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto 0 auto 10px;
      }
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 1px solid rgba(120, 130, 140, 0.2);
        background-color: transparent;
        transition: background-color ease-in-out 0.3s;
      }
      &:after {
        opacity: 0;
        content: "";
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNS4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTQzOC42IDEwNS40YzEyLjUgMTIuNSAxMi41IDMyLjggMCA0NS4zbC0yNTYgMjU2Yy0xMi41IDEyLjUtMzIuOCAxMi41LTQ1LjMgMGwtMTI4LTEyOGMtMTIuNS0xMi41LTEyLjUtMzIuOCAwLTQ1LjNzMzIuOC0xMi41IDQ1LjMgMEwxNjAgMzM4LjcgMzkzLjQgMTA1LjRjMTIuNS0xMi41IDMyLjgtMTIuNSA0NS4zIDB6Ii8+PC9zdmc+');
        background-repeat: no-repeat;
        background-size: 14px 14px;
        margin-left: 13px;
        width: 14px;
        height: 14px;
        text-align: center;
        border-radius: 20px;
        color: #fff;
        transition: opacity ease-in-out 0.3s;
      }
      & > span {
        padding-left: 26px;
        font-weight: 600;
      }
      &.active {
        &:before {
          border-color: $theme-primary-color;
          background-color: $theme-primary-color;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }
  &:not(.border-less) {
    .btn-group-toggle {
      border: 1px solid rgba(120, 130, 140, 0.2);
      border-radius: 0.25rem;
    }
  }
  &.border-less {
    .btn-group-toggle {
      & > label.btn-app-theme {
        &:not([disabled]).active {
          box-shadow: none;
        }
      }
    }
  }
}