// Transitions

@mixin transition($transition) {
  -webkit-transition: $transition;
          transition: $transition;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}
@mixin transition-transform($transition) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}

// Transformations
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9+
          transform: rotate($degrees);
}
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
      -ms-transform: scale($ratio); // IE9+
          transform: scale($ratio);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9+
          transform: translate($x, $y);
}
@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
      -ms-transform: skewX($x) skewY($y); // See https://github@mixin com/twbs/bootstrap/issues/4885; IE9+
          transform: skew($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}
@mixin translateZ($z) {
  -webkit-transform: translateZ($z);
    -ms-transform: translateZ($z);
     -o-transform: translateZ($z);
      transform: translateZ($z);
}
@mixin transform-origin($origin){
  -webkit-transform-origin: $origin;
     -moz-transform-origin: $origin;
      -ms-transform-origin: $origin;
          transform-origin: $origin;
}

@mixin backface-visibility($visibility){
  -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}
